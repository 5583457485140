export default interface NetProtocol{
    code:number;
    data?:any;
    msg:string;
    token:string;
}

export class LoginProto{
    public account:string="";
    public password:string="";
    public loginty:number=0;
}

export class LoginByTonken{
    public account:string="";
    public token:string="";
}

export class RefreshToken{
    public account:string="";
}

export class RegisterProto{
    public account:string="";
    public password:string="";
    public verifycode:string="";
}

export class VerifyCode{
    public account:string="";
    public actype:number=0;
}

export class ReSetPsdProto{
    public account:string="";
    public password:string="";
    public verifycode:string="";
}

export class ChangePsdProto{
    public account:string="";
    public oldpassword:string="";
    public newpassword:string="";
}

export class product{
    public orderid:string;
    public account:string;
    public details:string;
    public price:number;
    public state:number;
    public timed:number;
    public shopcart:cartdata[];
    public trackingnum:string;
}

export class cartdata{
    ProductId:string="";
    Names:string="";
    Price:number=0;
    ImgeId:number=0;
    Amount:number=0;
}

export class UserData{
    public account:string;
    public password:string;
    public actype:number;
    public fullname:string;
    public areacode:string;
    public phonenum:string;
    public country:string;
    public address:string;
    public postcodes:string;
    public products:number[];
    public shopcart:cartdata[];
}

export class UserAddress{
    public account:string;
    public fullname:string;
    public areacode:string;
    public phonenum:string;
    public country:string;
    public address:string;
    public postcodes:string;
}

export class GetUserAddress{
    public account:string;
    public useract:string;
}

export class CPaypalTokenProto{
    public account:string;
}

export class SPaypalTokenProto{
    public paypaltoken:string;
    public expires_in:number;
}

export class PayPalPayProto{
    public account:string="";
    public description:string="";
    public price:number=0;
    public items:cartdata[]=[];
}

export class PayPalCaptureProto{
    public account:string="";
    public orderid:string="";
}

export class PayPalCancelProto{
    public account:string;
    public orderid:string;
}

export class PayPalAddTrackingProto{
    public account:string;
    public orderid:string;
    public timed:number;
    public tracking_number:string;
    public carrier:string;
}

export class ShopCartProto{
    public account:string;
    public shopcart:cartdata[];
}

export class CGetOrthersProto{
    public account:string;
    public timeds:number[];
}

export class SGetOrthersProto{
    public orthers:product[];
}

export class CDelOrthersProto{
    public account:string;
    public timeds:number[];
}

export class SDelOrthersProto{
    public timeds:number[];
}

export class ServerOrdersProto{
    public account:string;
    public starttime: number;
    public endtime:number;
}