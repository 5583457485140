import classnames from 'classnames/bind';
import style from './index.module.scss';
import { useState } from "react";
import NetProtocol, { GetUserAddress } from '../../Net/NetProtocol';
import { HttpNetPost } from '../../Net/HttpNetMg';
import Common from '../../Common/Common';
const className = classnames.bind(style);
const star_icon = '/images/common/star_icon.png';

const UserAddress ={
  account:'',
  fullname:'',
  areacode:'',
  phonenum:'',
  country:'',
  address:'',
  postcodes:''
}

interface PropsTarget {
  useract: string
}

const ShowAddress = (props: PropsTarget)=>{
    const {useract} = props;
    const [showWinState, setWinState] = useState(false);
    const [address, setAddress] = useState(UserAddress);
    const onShow = async ()=>{
      let isshow = !showWinState;
      setWinState(isshow);
      if(isshow){
        let getaddr: GetUserAddress = new GetUserAddress();
        getaddr.account = Common.UserData.account;
        getaddr.useract = useract;
        const resData:NetProtocol = await HttpNetPost("/getcontact", getaddr, true);
        if(resData.code === 0){
          const useaddr = resData.data;
          setAddress({
            account:useaddr.account||"",
            fullname:useaddr.fullname||"",
            areacode:useaddr.areacode||"",
            phonenum:useaddr.phonenum||"",
            country:useaddr.country||"",
            address:useaddr.address||"",
            postcodes:useaddr.postcodes||""
          });
        }
      }
    }
    return(
        <div className={className('safety_center_wrap')}>
        <div className={className("safety_center_item_wrap")}>
          <div className={className('safety_center_item_title')} onClick={onShow}>
            <div className={className('left_safety_icon')}>
              <img src={star_icon} alt="star_icon" />
            </div>
            <div className={className('title_text')}>{"Shipping address"}</div>
            <div className={className('title_right_arrow', {title_right_arrow_up: showWinState})}></div>
          </div>
          <div className={className('safety_center_content_box')} style={{height: showWinState ? 'auto' : '0'}}>
            {
              address &&
            <div className={className('addressinfo')}>
                <span>{"fullname: "+address.fullname}</span><br/>
                <span>{"phone number: "+address.areacode+address.phonenum}</span><br/>
                <span>{"country: "+address.country}</span><br/>
                <span>{"address: "+address.address}</span><br/>
                <span>{"postcodes: "+address.postcodes}</span>
            </div>
            }
          </div>
        </div>
        </div>
    )
}

export default ShowAddress;