
import classnames from 'classnames/bind'
import style from './index.module.scss'
/** images */
const ImgRegisterSuccess = '/images/components/inputHintPop/register_success.png'
const ImgRegisterError = '/images/components/inputHintPop/register_error.png'

const className = classnames.bind(style)

interface InputHintPopProps {
  type: string,
  hintState: any,
  wrapStyle?: any
}

const InputHintPop = ({
  hintState,
  type='username',
  wrapStyle={}
}: InputHintPopProps) => {
  const userNameHintTextList: Array<string> = ['8-20 Characters', 'Contains only character or numbers'];
  const passwordHintTextList: Array<string> = ['8-20 Characters', 'Contains only character or numbers'];
  /** function */
  const getHintText = (i: number) => {
    let str = '';
    switch(type) {
      case 'username':
        str = userNameHintTextList[i];
        break;
      case 'password':
        str = passwordHintTextList[i];
        break;
    }
    return str;
  }

  return (
    <>
      <div className={className('hint_container')} style={wrapStyle}>
        <div className={className('hint_section')}>
          {
            Object.keys(hintState).map((item, i) => {
              const hintText: string = getHintText(i)
              return (
                <div className={className('hint_block')} key={i}>
                  <p className={className('hint_content')}>{'* ' + hintText}</p>
                  <div className={className('pic_size')}>
                    <img src={hintState[item] ? ImgRegisterSuccess : ImgRegisterError} alt="condition status" />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default InputHintPop
