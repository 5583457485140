import classnames from 'classnames/bind';
import style from './index.module.scss';
const className = classnames.bind(style);

interface PropsTarget {
    ImageUrl:string,
    CloseImg:()=>void
}

export default function ShowImge(propsdata: PropsTarget)
{
    const {ImageUrl, CloseImg} = propsdata;
    return(
        <div className={className('showimage')}>
            <div className={className('centerdiv')}>
                <div className={className('close')} onClick={CloseImg}>
                    <img src="/images/common/close_white.png"></img>
                </div>
                <img src={ImageUrl}></img>
            </div>
        </div>
    )
}