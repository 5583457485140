
export class NetByte
{
    private _u8d_: Uint8Array;
    private bytes: DataView;
    private _pos: number;
    constructor(arrays?: Uint8Array|ArrayBuffer, pos:number = 0){
        this._u8d_ = arrays? new Uint8Array(arrays): new Uint8Array(0);
        this.bytes = new DataView(this._u8d_.buffer);
        this._pos = pos;
    }

    static isInitENDIAN = false;
    static isLittleEndian = false;
    public static get IsLittleEndian():boolean {
        /*if(!this.isInitENDIAN){
            var buffer = new ArrayBuffer(2); // 创建一个长度为2的ArrayBuffer
            var view = new DataView(buffer); // 将ArrayBuffer转换成DataView对象
            view.setInt16(0, 257); // 设置第一个元素为257
            this.isLittleEndian = (view.getUint8(0) === 1 && view.getUint8(1) === 1); // 返回结果根据两个字节的值进行判断
        }
        return this.isLittleEndian;*/
        return false;
    }

    public get length(): number{
        return this.bytes.byteLength;
    }
    /**
     * <p> <code>Byte</code> 对象的长度（以字节为单位）。</p>
     * <p>如果将长度设置为大于当前长度的值，则用零填充字节数组的右侧；如果将长度设置为小于当前长度的值，将会截断该字节数组。</p>
     * <p>如果要设置的长度大于当前已分配的内存空间的字节长度，则重新分配内存空间
     */
    set length(value: number) {
        if(this.bytes.byteLength != value){
            this.ResizeBuffer(value);
        }
    }

    public get pos(): number{
        return this._pos;
    }

    public set pos(v: number){
        this._pos = v;
    }

    /**@private */
    public ResizeBuffer(len: number): void {
        try {
            var newByteView: any = new Uint8Array(len);
            if (this._u8d_ != null) {
                if (this._u8d_.length <= len) newByteView.set(this._u8d_);
                else newByteView.set(this._u8d_.subarray(0, len));
            }
            this._u8d_ = newByteView;
            this.bytes = new DataView(newByteView.buffer);
        } catch (err) {
            throw "Invalid typed array length:" + len;
        }
    }

    /**
     * 可从字节流的当前位置到末尾读取的数据的字节数。
     */
    get bytesAvailable(): number {
        return this.length - this._pos;
    }

    public readFloat64(): number{
        var v = this.bytes.getFloat64(this._pos,NetByte.IsLittleEndian);
        this._pos += 8;
        return v;
    }
    public writeFloat64(v: number): void{
        this._ensureWrite(this._pos + 8);
        this.bytes.setFloat64(this._pos, v,NetByte.IsLittleEndian);
        this._pos += 8;
    }

    public readFloat32(): number{
        var v = this.bytes.getFloat32(this._pos,NetByte.IsLittleEndian);
        this._pos += 4;
        return v;
    }
    public writeFloat32(v: number): void{
        this._ensureWrite(this._pos + 4);
        this.bytes.setFloat32(this._pos, v,NetByte.IsLittleEndian);
        this._pos += 4;
    }

    public readInt32(): number{
        var v = this.bytes.getInt32(this._pos,NetByte.IsLittleEndian);
        this._pos += 4;
        return v;
    }
    public writeInt32(v: number): void{
        this._ensureWrite(this._pos + 4);
        this.bytes.setInt32(this._pos, v,NetByte.IsLittleEndian);
        this._pos += 4;
    }

    public readUint32(): number{
        var v = this.bytes.getUint32(this._pos,NetByte.IsLittleEndian);
        this._pos += 4;
        return v;
    }
    public writeUint32(v: number): void{
        this._ensureWrite(this._pos + 4);
        this.bytes.setUint32(this._pos, v,NetByte.IsLittleEndian);
        this._pos += 4;
    }

    public readInt16(): number{
        var v = this.bytes.getInt16(this._pos,NetByte.IsLittleEndian);
        this._pos += 2;
        return v;
    }
    public writeInt16(v: number): void{
        this._ensureWrite(this._pos + 2);
        this.bytes.setInt16(this._pos, v,NetByte.IsLittleEndian);
        this._pos += 2;
    }

    public readUint16(): number{
        var v = this.bytes.getUint16(this._pos,NetByte.IsLittleEndian);
        this._pos += 2;
        return v;
    }
    public writeUint16(v: number): void{
        this._ensureWrite(this._pos + 2);
        this.bytes.setUint16(this._pos, v,NetByte.IsLittleEndian);
        this._pos += 2;
    }

    public readInt8(): number{
        var v = this.bytes.getInt8(this._pos);
        this._pos++;
        return v;
    }
    public writeInt8(v: number): void{
        this._ensureWrite(this._pos + 1);
        this.bytes.setInt8(this._pos, v);
        this._pos++;
    }

    public readUint8(): number{
        var v = this.bytes.getUint8(this._pos);
        this._pos++;
        return v;
    }
    public writeUint8(v: number): void{
        this._ensureWrite(this._pos + 1);
        this.bytes.setUint8(this._pos, v);
        this._pos++;
    }

    public readUTFString(): string{
        return this.readUTFBytes(this.readUint16());
    }

    /**
     * @private
     * 读字符串，必须是 writeUTFBytes 方法写入的字符串。
     * @param len	要读的buffer长度，默认将读取缓冲区全部数据。
     * @return 读取的字符串。
     */
    readUTFBytes(len: number = -1): string {
        if (len === 0) return "";
        var lastBytes: number = this.bytesAvailable;
        if (len > lastBytes) throw "readUTFBytes error - Out of bounds";
        len = len > 0 ? len : lastBytes;
        return this._rUTF(len);
    }


    /**
     * @internal
     * <p>保证该字节流的可用长度不小于 <code>lengthToEnsure</code> 参数指定的值。</p>
     * @param	lengthToEnsure	指定的长度。
     */
    _ensureWrite(lengthToEnsure: number): void {
        if (this.length < lengthToEnsure) this.length = lengthToEnsure;
    }

    /**
     * @private
     * 读取指定长度的 UTF 型字符串。
     * @param	len 需要读取的长度。
     * @return 读取的字符串。
     */
    private _rUTF(len: number): string {
        var max: number = this._pos + len, c: number, c2: number, c3: number, f: Function = String.fromCharCode;
        var u: any = this._u8d_, i: number = 0;
        var strs: any[] = [];
        var n: number = 0;
        strs.length = 1000;
        while (this._pos < max) {
            c = u[this._pos++];
            if (c < 0x80) {
                if (c != 0)
                    strs[n++] = f(c);
            } else if (c < 0xE0) {
                strs[n++] = f(((c & 0x3F) << 6) | (u[this._pos++] & 0x7F));
            } else if (c < 0xF0) {
                c2 = u[this._pos++];
                strs[n++] = f(((c & 0x1F) << 12) | ((c2 & 0x7F) << 6) | (u[this._pos++] & 0x7F));
            } else {
                c2 = u[this._pos++];
                c3 = u[this._pos++];
                const _code = ((c & 0x0F) << 18) | ((c2 & 0x7F) << 12) | ((c3 & 0x7F) << 6) | (u[this._pos++] & 0x7F);
                if (_code >= 0x10000) {
                    const _offset = _code - 0x10000;
                    const _lead = 0xd800 | (_offset >> 10);
                    const _trail = 0xdc00 | (_offset & 0x3ff);
                    strs[n++] = f(_lead);
                    strs[n++] = f(_trail);
                }
                else {
                    strs[n++] = f(_code);
                }
            }
            i++;
        }
        strs.length = n;
        return strs.join('');
    }

    /**
     * <p>将 UTF-8 字符串写入字节流。类似于 writeUTF() 方法，但 writeUTFBytes() 不使用 16 位长度的字为字符串添加前缀。</p>
     * <p>对应的读取方法为： getUTFBytes 。</p>
     * @param value 要写入的字符串。
     */
    writeUTFBytes(value: string): void {
        value = value + "";
        for (var i: number = 0, sz: number = value.length; i < sz; i++) {
            var c: number = value.charCodeAt(i);
            if (c <= 0x7F) {
                this.writeInt8(c);
            } else if (c <= 0x7FF) {
                //优化为直接写入多个字节，而不必重复调用writeByte，免去额外的调用和逻辑开销。
                this._ensureWrite(this._pos + 2);
                this._u8d_.set([0xC0 | (c >> 6), 0x80 | (c & 0x3F)], this._pos);
                this._pos += 2;
            } else if (c >= 0xD800 && c <= 0xDBFF) {
                i++;
                const c2 = value.charCodeAt(i);
                if (!Number.isNaN(c2) && c2 >= 0xDC00 && c2 <= 0xDFFF) {
                    const _p1 = (c & 0x3FF) + 0x40;
                    const _p2 = c2 & 0x3FF;
                    const _b1 = 0xF0 | ((_p1 >> 8) & 0x3F);
                    const _b2 = 0x80 | ((_p1 >> 2) & 0x3F);
                    const _b3 = 0x80 | ((_p1 & 0x3) << 4) | ((_p2 >> 6) & 0xF);
                    const _b4 = 0x80 | (_p2 & 0x3F);
                    this._ensureWrite(this._pos + 4);
                    this._u8d_.set([_b1, _b2, _b3, _b4], this._pos);
                    this._pos += 4;
                }
            } else if (c <= 0xFFFF) {
                this._ensureWrite(this._pos + 3);
                this._u8d_.set([0xE0 | (c >> 12), 0x80 | ((c >> 6) & 0x3F), 0x80 | (c & 0x3F)], this._pos);
                this._pos += 3;
            } else {
                this._ensureWrite(this._pos + 4);
                this._u8d_.set([0xF0 | (c >> 18), 0x80 | ((c >> 12) & 0x3F), 0x80 | ((c >> 6) & 0x3F), 0x80 | (c & 0x3F)], this._pos);
                this._pos += 4;
            }
        }
    }

    public writeUTFString(v: string){
        let sPos: number = this._pos;
        this.writeUint16(0);
        this.writeUTFBytes(v);
        let lens: number = this._pos - sPos - 2;
        this.bytes.setUint16(sPos, lens, NetByte.IsLittleEndian);
    }

    /**
    * <p>将指定 arraybuffer 对象中的以 offset 为起始偏移量， length 为长度的字节序列写入字节流。</p>
    * <p>如果省略 length 参数，则使用默认长度 0，该方法将从 offset 开始写入整个缓冲区；如果还省略了 offset 参数，则写入整个缓冲区。</p>
    * <p>如果 offset 或 length 小于0，本函数将抛出异常。</p>
    * @param	arraybuffer	需要写入的 Arraybuffer 对象。
    * @param	offset		Arraybuffer 对象的索引的偏移量（以字节为单位）
    * @param	length		从 Arraybuffer 对象写入到 Byte 对象的长度（以字节为单位）
    */
    writeArrayBuffer(arraybuffer: any, offset: number = 0, length: number = 0): void {
        if (offset < 0 || length < 0) throw "writeArrayBuffer error - Out of bounds";
        if (length == 0) length = arraybuffer.byteLength - offset;
        var uint8array: any = new Uint8Array(arraybuffer);
        this._ensureWrite(this._pos + length);
        this._u8d_.set(uint8array.subarray(offset, offset + length), this._pos);
        this._pos += length;
    }

    /**
    * 读取ArrayBuffer数据
    * @param	length
    * @return
    */
    readArrayBuffer(length: number): ArrayBuffer {
        var rst: ArrayBuffer;
        rst = this._u8d_.buffer.slice(this._pos, this._pos + length);
        this._pos += length;
        return rst;
    }

    /**
    * 读取ArrayBuffer剩余数据
    * @param	length
    * @return
    */
    readResidueBuffer(): ArrayBuffer {
        var rst: ArrayBuffer;
        rst = this._u8d_.buffer.slice(this._pos, this.length);
        return rst;
    }

    GetDataView():DataView{
        return this.bytes;
    }

    GetUint8Array():Uint8Array{
        return this._u8d_;
    }
}