import { NetCom } from "../Net/NetCom";

export enum Protocol{
    heartbeat = 0,
    clogin=11,
    slogin,
    sloginerro,
    cchat,
    schat,
    cread,
    sread,
    cgetchat,
    sgetchat
}

export class CWsLogin extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public static token = NetCom.bit.string;
    public account = "";
    public token = "";
}

export class SWsLoginErro extends NetCom.BaseMessage{
    public static errocode = NetCom.bit.byte;
    public errocode:number = 0;
}

export class ChatData extends NetCom.BaseMessage{
    public static official = NetCom.bit.byte;
    public static times = NetCom.bit.uint;
    public static chat = NetCom.bit.string;
    public official = 0;
    public times = 0;
    public chat = "";
}

export class ChatTimes extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public static ctime = NetCom.bit.uint;
    public static isread1 = NetCom.bit.uint;
    public static isread2 = NetCom.bit.uint;
    public account:string = "";
    public ctime:number = 0;
    public isread1:number = 0; // 官方已读
    public isread2:number = 0; // 用户已读
}

export class SWsLogin extends NetCom.BaseMessage{
    public static chattimes = new NetCom.ArrayTable(ChatTimes);
    public chattimes:ChatTimes[] = [];
}

export class CWsChat extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public static chat = NetCom.bit.string;
    public account = "";
    public chat = "";
}

export class SWsChat extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public static chat = new NetCom.ClassTb(ChatData);
    public account = "";
    public chat:ChatData;
}

export class CWsRead extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public account = "";
}

export class SWsRead extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public static official = NetCom.bit.byte;
    public account = "";
    public official = 0;
}

export class CWsGetChat extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public static times = NetCom.bit.uint;//收到最后一条消息的时间
    public account = "";
    public times = 0;
}

export class SCWsGetChat extends NetCom.BaseMessage{
    public static account = NetCom.bit.string;
    public static records = new NetCom.ArrayTable(ChatData);
    public account = "";
    public records:ChatData[] = [];
}