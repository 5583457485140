import Common from "./Common";

export default class CommonData{
    private static instance:CommonData;
    public static get Instance() : CommonData{
        if(this.instance == null){
            this.instance = new CommonData();
        }
        return this.instance;
    }

    private showLoading = false;
    private showService = false;
    private showChat = false;
    private showiframe = false;
    private showlogin = false;
    private showaccount = false;
    private showaddress = false;
    private popTips= '';// 文字提示
    private messageTarget=null;// 左上角消息提示
    private router:any;

    public get ShowLoading(): boolean{
        return  this.showLoading;
    }

    public set ShowLoading(v: boolean){
        this.showLoading = v;
        Common.Common = this;
    }

    public get PopTips(): string{
        return  this.popTips;
    }

    public set PopTips(v: string){
        this.popTips = v;
        Common.Common = this;
    }

    public get ShowService(): boolean{
        return  this.showService;
    }

    public set ShowService(v: boolean){
        this.showService = v;
        Common.Common = this;
    }

    public get ShowChat(): boolean{
        return  this.showChat;
    }

    public set ShowChat(v: boolean){
        this.showChat = v;
        Common.Common = this;
    }

    public get ShowIframe(): boolean{
        return  this.showiframe;
    }

    public set ShowIframe(v: boolean){
        this.showiframe = v;
        Common.Common = this;
    }

    public get ShowLogin(): boolean{
        return  this.showlogin;
    }

    public set ShowLogin(v: boolean){
        this.showlogin = v;
        Common.Common = this;
    }

    public get MessageTarget():any{
        return  this.messageTarget;
    }

    public set MessageTarget(v: any){
        this.messageTarget = v;
        Common.Common = this;
    }

    public get ShowAccount():boolean{
        return this.showaccount;
    }

    public set ShowAccount(v:boolean){
        this.showaccount = v;
        Common.Common = this;
    }

    public get ShowAddress():boolean{
        return this.showaddress;
    }

    public set ShowAddress(v:boolean){
        this.showaddress = v;
        Common.Common = this;
    }

    public get NRouter():any{
        return  this.router;
    }

    public set NRouter(v: any){
        this.router = v;
        Common.Common = this;
    }
}