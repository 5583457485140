import { NetByte } from "./NetByte";
import { Protocol } from "./WsProtocol";
import Common from "../Common/Common";

export default class NetWebSocket
{
    private socket: WebSocket|null;
    private netdic: Map<number, Function>;
    private heartbeatBuf: Uint8Array;
    public onerror: Function;
    public onclose: Function;
    public onopen: Function;
    private hearttime: number = 0;
    private isConneting: boolean = false;
    private heartbeatTimer: NodeJS.Timeout|null;
    constructor(){
        this.netdic = new Map<number, Function>();
        this.isConneting = false;
        let heartBytes = new NetByte();
        heartBytes.writeUint16(1);
        heartBytes.writeUint8(Protocol.heartbeat);
        this.heartbeatBuf = heartBytes.GetUint8Array();
    }
    // 初始化 WebSocket 连接
    public OpenSocket(serverURL:string) {
        this.CloseSocket();
        this.isConneting = true;
        this.socket = new WebSocket(serverURL);
        this.socket.binaryType = "arraybuffer";
        this.socket.onopen = (event) => {
            console.log('WebSocket connected!');
            this.hearttime = 0;
            this.isConneting = false;
            this.onopen?.();
            this.heartbeatTimer = setInterval(this.StartHeartBeat.bind(this), 30000);
        };
        this.socket.onmessage = (event) => {
            console.log('Received message:', event.data);
            if (event.data instanceof ArrayBuffer) {
                var bytes: NetByte = new NetByte(event.data);
                this.RunNet(bytes);
            }
            else{
                console.error("收到错误类型协议:", event.data);
            }
        };
        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.socket = null;
            this.isConneting = false;
            if(this.heartbeatTimer){
                clearInterval(this.heartbeatTimer);
                this.heartbeatTimer = null;
            }
            this.onerror?.();
        };
        this.socket.onclose = (event) => {
            console.log('WebSocket closed:', event);
            this.socket = null;
            this.isConneting = false;
            if(this.heartbeatTimer){
                clearInterval(this.heartbeatTimer);
                this.heartbeatTimer = null;
            }
            this.onclose?.();
        };
    }

    private StartHeartBeat(){
        this.hearttime += 30000;
        if(this.hearttime < 60000){
            this.socket?.send(this.heartbeatBuf);
        }
        else{
            this.CloseSocket();
        }
    }

    // 发送数据到服务器
    public SendData(data:NetByte) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(data.GetUint8Array());
        }
        else if(!this.isConneting){
            if(!Common.UserData){
                Common.Common.ShowLogin = true;
            }
            else if(process.env.CHAT_SERVER){
                this.OpenSocket(process.env.CHAT_SERVER);
            }
        }
    }

    public get IsOpen(): boolean{
        return this.socket?.readyState === WebSocket.OPEN || false;
    }

    // 关闭 WebSocket 连接
    public CloseSocket() {
        this.isConneting = false;
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
        if(this.heartbeatTimer){
            clearInterval(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }
    }

    public AddNet(msgid: number, backfunc: Function){
        this.netdic.set(msgid, backfunc);
    }

    public RemoveNet(msgid: number)
    {
        this.netdic.delete(msgid);
    }

    private RunNet(bytes: NetByte){
        let lens = bytes.readUint16();
        let lastlen = bytes.pos + lens;
        let msgid = bytes.readUint8();
        if(msgid === Protocol.heartbeat){
            this.hearttime = 0;
        }
        else{
            this.netdic.get(msgid)?.(bytes);
        }
        if(bytes.length - lastlen >= 3){
            bytes.pos = lastlen;
            this.RunNet(bytes);
        }
    }
}