import Common from "../Common/Common";
import { HttpCommon } from "./HttpCommon";
import NetProtocol from "./NetProtocol";

export function HttpNetPost(url:string, netdata?:any, isshowerro=false):Promise<NetProtocol>{
    return new Promise<any>((resolve, reject) => {
    Common.Common.ShowLoading = true;
    HttpCommon.postString(url, netdata, (statusCode, respText)=>{
        Common.Common.ShowLoading = false;
        if(statusCode === 200){
            const resData:NetProtocol = JSON.parse(respText);
            if(resData.code === 1){
                Common.UserData = null;
                Common.Common.ShowLogin = true;
            }
            else{
                if(resData.code !== 0 && isshowerro){
                    Common.Common.PopTips = resData.msg;
                }
                else{
                    resolve(resData);
                }
            }
        }
        else{
            if(isshowerro){
                Common.Common.PopTips = respText;
            }
            else{
                resolve({code:-1, msg:respText});
            }
        }
    });
    });
}

export function HttpNetGet(url:string, isshowerro=false):Promise<NetProtocol>{
    return new Promise<any>((resolve, reject) => {
    Common.Common.ShowLoading = true;
    HttpCommon.getString(url, (statusCode, respText)=>{
        Common.Common.ShowLoading = false;
        if(statusCode === 200){
            const resData:NetProtocol = JSON.parse(respText);
            if(resData.code === 1){
                Common.UserData = null;
                Common.Common.ShowLogin = true;
            }
            else{
                if(resData.code !== 0 && isshowerro){
                    Common.Common.PopTips = resData.msg;
                }
                else{
                    resolve(resData);
                }
            }
        }
        else{
            if(isshowerro){
                Common.Common.PopTips = respText;
            }
            else{
                resolve({code:-1, msg:respText});
            }
        }
    });
    });
}

export async function HttpWebGet(url:string){
    return new Promise<any>((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        HttpCommon.SetRequestHeader(xhr);
        xhr.onreadystatechange = function () {
            switch(xhr.readyState){
                case 4:
                    resolve({status:xhr.status, text:xhr.responseText});
                    break;
            }
        };
        xhr.onerror = function (err) {
            resolve({status:-1, text:"Network error"});
        };
        xhr.send();
    });
}



export async function UploadFile(file:any) {
    return new Promise<any>((resolve, reject) => {
        var formData = new FormData();
        formData.append('image', file);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.PUBLIC_API_BASE}/upload`, true);
        //xhr.setRequestHeader("Content-Type","multipart/form-data");
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            if(xhr.status === 200){
                console.log('图片上传成功!: ', xhr.responseText);
                resolve(JSON.parse(xhr.responseText));
            }
            else{
                console.log('图片上传失败!');
                resolve({code:1});
            }
          }
        };
        xhr.send(formData);
    });
}