import classnames from 'classnames/bind';
import style from './index.module.scss';
import { useState } from "react";
import Common from '../../../Common/Common';
const className = classnames.bind(style);
const star_icon = '/images/common/star_icon.png';

const ShowAddress = ()=>{
    const [showWinState, setWinState] = useState(false);
    const onSubmitEditor = ()=>{
        Common.Common.ShowAccount = false;
        Common.Common.ShowAddress = true;
    }
    return(
        <div className={className('safety_center_wrap')}>
        <div className={className("safety_center_item_wrap")}>
          <div className={className('safety_center_item_title')} onClick={() => setWinState(!showWinState)}>
            <div className={className('left_safety_icon')}>
              <img src={star_icon} alt="star_icon" />
            </div>
            <div className={className('title_text')}>{"Shipping address"}</div>
            <div className={className('title_right_arrow', {title_right_arrow_up: showWinState})}></div>
          </div>
          <div className={className('safety_center_content_box')} style={{height: showWinState ? 'auto' : '0'}}>
            <div className={className('addressinfo')}>
                <span>{"fullname: "+Common.UserData.fullname}</span><br/>
                <span>{"phone number: "+Common.UserData.areacode+Common.UserData.phonenum}</span><br/>
                <span>{"country: "+Common.UserData.country}</span><br/>
                <span>{"address: "+Common.UserData.address}</span><br/>
                <span>{"postcodes: "+Common.UserData.postcodes}</span>
            </div>
            <button className={className('submit_btn')} onClick={onSubmitEditor}>Modify</button>
          </div>
        </div>
        </div>
    )
}

export default ShowAddress;