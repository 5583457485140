import Common from "../Common/Common";
import { HttpNetPost } from "../Net/HttpNetMg";
import { UserAddress } from "../Net/NetProtocol";
import CountryData from "./CountryData";

export namespace Define {
    export function CheckUserAddress(naddress:any): boolean{
        const userinfo = Common.UserData;
        if(!userinfo){
            return false;
        }
        let isBiandDdr = true;
        const {fullname,areacode,phonenum,country,address} = userinfo;
        if(!fullname){
            userinfo.fullname = naddress.name || "";
            isBiandDdr = false;
        }
        if(!areacode || !phonenum){
            if(naddress.phone){
                let phonestr: string = naddress.phone;
                userinfo.areacode = phonestr.substring(0, 3);
                userinfo.phonenum = phonestr.substring(3);
            }
            isBiandDdr = false;
        }
        if(!country){
            if(naddress.country_code){
                const countrydt = CountryData[naddress.country_code];
                if(countrydt){
                    userinfo.country = countrydt[0];
                }
            }
            isBiandDdr = false;
        }
        if(!address){
            userinfo.address = "";
            userinfo.address += naddress.area_2?naddress.area_2+"," : "";
            userinfo.address += naddress.area_1?naddress.area_1+"," : "";
            userinfo.address += naddress.address2?naddress.address2+"," : "";
            userinfo.address += naddress.address1?naddress.address1+"," : "";
            isBiandDdr = false;
        }
        if(!isBiandDdr){
            let useradr: UserAddress = new UserAddress();
            useradr.account = userinfo.account;
            useradr.fullname = userinfo.fullname;
            useradr.areacode = userinfo.areacode;
            useradr.phonenum = userinfo.phonenum;
            useradr.country = userinfo.country;
            useradr.address = userinfo.address;
            useradr.postcodes = userinfo.postcodes;
            HttpNetPost("/setcontact", useradr, true);
        }
        return isBiandDdr;
    }
}