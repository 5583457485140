
import { useState } from 'react';
import { validEmail, validContainsEnglishNumbers, validContainsSymbols, validFirstWordCapital, validPassword, validTextLength } from '../../../Api/validation';
import Common from '../../../Common/Common';

const initialForm = {
  email:'',
  password: '',
}

export default function useSignNameInput() {
  const [nameInput, setNameInput] = useState(initialForm);
  const {email, password} = nameInput;
  const [invalidName, setInvalidLogin] = useState({
    email:{
      flag: false,
      errMsg: ''
    },
    password:{
      flag: false,
      errMsg: ''
    }
  })

  const resetInput = ()=>{
    setInvalidLogin(
      {
        email: {
          flag: false,
          errMsg: ''
        },
        password:{
          flag: false,
          errMsg: ''
        }
      }
    )
  }

  const isValidNameInput = () => {
    if(Common.Common.ShowLoading) return;
    if(validEmail(email) && validPassword(password)){
      resetInput();
      return true;
    }
    setInvalidLogin({
      email: {
        flag: email === '' || !validEmail(email),
        errMsg: emailValid()
      },
      password: {
        flag: password === '' || !validPassword(password),
        errMsg: passwordVaild()
      }
    })
    return false;
  }

  const isValidEmailInput = () =>{
    if(validEmail(email)){
      resetInput();
      return true;
    }
    setInvalidLogin({
      email: {
        flag: email === '' || !validEmail(email),
        errMsg: emailValid()
      },
      password:{
        flag: false,
        errMsg: ''
      }
    })
    return false;
  }

  const emailValid = () => {
    if (!email) {
      return "Email can't be empty.";
    } else if (!validEmail(email)) {
      return "Please enter correct email.";
    } else {
      return ''
    }
  }

  const passwordVaild = () => {
    if (password === '') {
      // 檢查密碼是否為空
      return "Password can't be empty.";
    } else if (!validTextLength(password)) {
      // 檢查長度是否為8-20字元
      return "Please Enter 8-20 Characters";
    } else if (!validContainsSymbols(password)) {
      // 檢查是否包含特殊符號
      return "The password cannot contain special symbols.";
    } else if (!validContainsEnglishNumbers(password)) {
      // 檢查是否包含英文及數字
      return "Password must contain English and numbers.";
    } else if (!validFirstWordCapital(password)) {
      // 檢查第一個字元是否為大寫英文字母
      return "First with capitalization.";
    } else {
      return 'Password must contain English in small caps'
    }
  }

  // 修改表单项
  const onUpdateNameInput = (key: string, value: string | string[]): void => {
    const temp:any = {}
    temp[key] = value;
    setNameInput(Object.assign({}, nameInput, temp))
  }

  return {
    isValidNameInput,
    onUpdateNameInput,
    isValidEmailInput,
    nameInput,
    invalidName,
  }
}
