import classnames from 'classnames/bind';
import style from './index.module.scss';
import Common from '../../../Common/Common';
import { useEffect, useState } from 'react';
import ForgetPwd from '../ForgetPwd';
import useSignNameInput from '../hooks/useSignNameInput';
import SignNameInput from '../SignNameInput';
import UserInfoData from '../../../Common/UserInfoData';
import { LoginProto } from '../../../Net/NetProtocol';
import GoogleLogin from '../GoogleLogin';

const className = classnames.bind(style)

const LoginPop = ()=>{
    const [showForgetPwdPop, setShowForgetPwdPop] = useState(false);
    const { nameInput, invalidName, onUpdateNameInput, isValidNameInput} = useSignNameInput();

    const onCheckLogin = ()=>{
        if(isValidNameInput()){
            const {email, password} = nameInput;
            let ndata:LoginProto = new LoginProto();
            ndata.account = email;
            ndata.password = password;
            ndata.loginty = 0;
            UserInfoData.Instance.UserLogin(ndata);
        }
    }

    const googleLoginBack = (data?:any)=>{
        if(data){
            let ndata:LoginProto = new LoginProto();
            ndata.account = data.email;
            ndata.password = data.email;
            ndata.loginty = 1;
            UserInfoData.Instance.UserLogin(ndata);
        }
        else{
            Common.Common.ShowLoading = false;
        }
    }

    useEffect(()=>{
        const loginstr = localStorage?.getItem('LoginAccount');
        if(loginstr){
            let loginac = JSON.parse(loginstr);
            const {account, password, loginty} = loginac;
            if(loginty === 0){
                nameInput.email = account;
                nameInput.password = password;
            }
        }
    },[])
    
    return(
        <>
        <div className={className('login_wrap')}>
            <SignNameInput nameInput={nameInput} onUpdateNameInput={onUpdateNameInput} invalidName={invalidName}/>
            <p className={className('forget_password')}>
              <span onClick={() => { setShowForgetPwdPop(true) }}> {"Forgot your password?"} </span>
            </p>
            <div className={className('btn_start')} style={{filter: `grayscale(${Common.Common.ShowLoading ? 1 : 0})`}} onClick={onCheckLogin}>
              <span>{"Log in to your account"}</span>
            </div>
            <p className={className('continue_with_text')} style={{ marginBottom: '16px' }}>
              {"or Continue With"}
            </p>
            <div className={className('other_sign_block')}>
                <GoogleLogin LoginBack={googleLoginBack}/>
            </div>
        </div>
        {showForgetPwdPop && (<ForgetPwd hidePop={() => { setShowForgetPwdPop(false) }} />)}
        </>
    )
}

export default LoginPop;