import style from './index.module.css'

export default function Loading() {
  return (
    <section className={style.regiter_loading_wrap}>
      <div className={style.mask}></div>
      <section className={style.regiter_loading}>
        <img className={style.loading_bg}src={'/images/common/loading_bg.png'} alt={'loading_bg'}/>
        <img className={style.loading_content} src={'/images/common/loading_content.png'} alt={'loading_content'}/>
      </section>
  </section>
  )
}
