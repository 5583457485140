import { ReactElement, useState } from 'react'
import classnames from 'classnames/bind'
import style from './index.module.scss'
import InputErrMsg from '../InputError'
const ImgEye = '/images/components/input/eye.png'
const ImgEyeClose = '/images/components/input/eye_close.png'
const className = classnames.bind(style)

interface InputProps {
  mode?: string,
  type?: string,
  label?: string,
  value?: string | Array<string>,
  maxLength?: number
  changeInputValue?: (parmas?: any, e?: any) => void,
  labelColorWhite?: string,
  customLabel?: ReactElement | any,
  invalidFlag?: boolean,
  invalidMsg?: string,
  required?: boolean,
  customRightBtn?: ReactElement | null,
  inputStyle?: object | undefined,
  imgMoney?: any,
  displayIcon?: any,
  btnMoneyTxt?: string | null,
  clickBtnMoney?: () => void,
  customMoneyBtn?: ReactElement | null,
  placeholder?: string,
  placeholderPos?: string,
  customInput?: any | null,
  labelRight?: string | null,
  disabled?: boolean,
  inputBlockStyle?: object | undefined,
  labelStyle?: object | undefined,
  errStyle?: any,
  options?:string[],
  focusInput?: () => void,
  blurInput?: () => void
}


const Input = ({
  mode = 'general',
  type = 'text',
  label,
  value,
  maxLength,
  changeInputValue = () => ({}),
  customLabel = null,
  invalidFlag = false,
  invalidMsg,
  required = false,
  customRightBtn = null,
  inputStyle = {},
  imgMoney = null,
  displayIcon,
  btnMoneyTxt,
  clickBtnMoney = () => ({}),
  customMoneyBtn = null,
  placeholder,
  placeholderPos = 'left',
  customInput = null,
  labelRight = null,
  disabled = false,
  inputBlockStyle = {},
  labelStyle = {},
  errStyle = {},
  options = [],
  focusInput = () => ({}),
  blurInput = () => ({})
}:InputProps) => {
  /* other */
  const placeholderClassname = placeholderPos === 'left' ? '' : 'placeholder_center'

  /* state */
  const [pwdType, setPwdType] = useState('password')

  /* function */
  const onChangePwdType = () => setPwdType(pwdType === 'password' ? 'text' : 'password')
  return (
    <>
      <div className={className('input_container', `${invalidFlag ? 'input_container_mb' : ''}`)}>
        {customLabel || (
          <div className={className('input_title_wrap')}>
            <label className={className('input_title')} style={labelStyle}>{label}</label>
            {required && <span className={className('input_title_required')}>*</span>}
            {labelRight && <span className={className('label_right_text')}>{labelRight}</span>}
          </div>
        )}
        {mode === 'general' && (
          <>
            <div className={className('input_block')} style={{ ...inputBlockStyle }}>
              <input
                maxLength={maxLength}
                type={type}
                pattern={type === 'number' ? '[0-9]*' : undefined}
                className={className(`${placeholderClassname}`)}
                value={value}
                placeholder={placeholder}
                onChange={e => changeInputValue(e.target.value)}
                style={{
                  border: invalidFlag ? '1px solid red' : 'none',
                  ...inputStyle
                }}
                disabled={disabled}
                onFocus={() => focusInput()}
                onBlur={() => blurInput()}
              />
              {customRightBtn}
            </div>
            {required && <InputErrMsg flag={invalidFlag} msg={invalidMsg} errStyle={errStyle}/>}
          </>
        )}
        {mode === 'password' && (
          <>
            <div
              className={className('contain_block')}
              style={{
                border: invalidFlag ? '1px solid red' : 'none',
                ...inputBlockStyle
              }}
            >
              <input
                type={pwdType}
                autoComplete='off'
                className={className(`${placeholderClassname}`)}
                value={value}
                placeholder={placeholder}
                onChange={e => changeInputValue(e.target.value)}
                style={{ 
                  border: invalidFlag ? '1px solid red' : 'none',
                  ...inputStyle }}
                disabled={disabled}
                onFocus={() => focusInput()}
                onBlur={() => blurInput()}
              />
              <div className={className('input_right_icon')} onClick={() => onChangePwdType()}>
                <img src={pwdType === 'text' ? ImgEye : ImgEyeClose} alt='Eye' width={20} height={13} />
              </div>
            </div>
            {required && <InputErrMsg flag={invalidFlag} msg={invalidMsg}/>}
          </>
        )}
        {mode === 'money' && (
          <>
            <div className={className('input_block')} style={{ ...inputBlockStyle }}>
              <div className={className('pr-3 rounded-l-md', 'money_block')}>
                <input
                  type={type}
                  className={className(`${placeholderClassname}`)}
                  value={value}
                  placeholder={placeholder}
                  onChange={e => changeInputValue(e.target.value)}
                  style={{
                    border: invalidFlag ? '1px solid red' : 'none',
                    ...inputStyle
                  }}
                  disabled={disabled}
                  pattern='[0-9]*'
                />
                {imgMoney && <img src={imgMoney} alt='Money' width={12} height={12} />}
              </div>
              {customMoneyBtn || (
                <button
                  className={className('text-white text-center text-sm rounded-sm', 'btn_money')}
                  onClick={() => clickBtnMoney()}
                >
                  {btnMoneyTxt}
                </button>
              )}
            </div>
            {required && <InputErrMsg flag={invalidFlag} msg={invalidMsg} />}
          </>
        )}
        {mode === 'email' && (
          <>
            <div className={className('input_block')}>
              <div
                className={className('email_input')}
                style={{
                  border: invalidFlag ? '1px solid red' : 'none',
                  ...inputBlockStyle
                }}
              >
                <input
                  type={type}
                  autoComplete='off'
                  className={className(`${placeholderClassname}`)}
                  value={value}
                  placeholder={placeholder}
                  onChange={e => changeInputValue(e.target.value)}
                  style={{
                    border: invalidFlag ? '1px solid red' : 'none',
                    ...inputStyle }}
                  disabled={disabled}
                />
                {displayIcon && <div className={className('displayIcon')}>
                  <img src={displayIcon} alt='vaild' width={26} height={26} />
                </div>}
              </div>
              {customRightBtn}
            </div>
            {required && <InputErrMsg flag={invalidFlag} msg={invalidMsg} />}
          </>
        )}
        {
          mode === 'selection' &&(
            <>
            <div className={className('input_block')} style={{ ...inputBlockStyle }}>
                <input
                  type={type}
                  autoComplete='off'
                  className={className(`${placeholderClassname}`)}
                  value={value}
                  placeholder={placeholder}
                  onChange={e => changeInputValue(e.target.value)}
                  style={{
                    border: invalidFlag ? '1px solid red' : 'none',
                    ...inputStyle
                  }}
                  disabled={disabled}
                  list='browsers'
                />
                  <datalist id='browsers'>
                  {options && options.map((item: any, index: number) => {
                    return(
                      <option key={index} value={item}>{item}</option>
                    )
                  })}
                  </datalist>
            </div>
            {required && <InputErrMsg flag={invalidFlag} msg={invalidMsg} />}
            </>
          )
        }
        {mode === 'custom' && (
          <>
            <div
              className={className('rounded-md', 'contain_block')}
              style={{
                border: invalidFlag ? '1px solid red' : '1px solid #09252A',
                ...inputBlockStyle
              }}
            >
              {customInput}
            </div>
            {required && <InputErrMsg flag={invalidFlag} msg={invalidMsg} />}
          </>
        )}
      </div>
    </>
  )
}

export default Input
