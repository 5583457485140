import { useRef, useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';
import style from './index.module.scss';
import {
  GuideSuccessTips,
  UserLoginCompleteTips
} from './MessageDom';
import Common from '../../Common/Common';
 /**
 * images
 */
const ImgMsgPerson = '/images/components/alertMsg/msg_person.png';
const ImgMsgErrorRed = '/images/components/alertMsg/msg_error_red.png';
const ImgMsgEmail = '/images/components/alertMsg/msg_email.png';
const ImgMsgKey = '/images/components/alertMsg/msg_key.png';
const ImgMsgWithdrawal = '/images/components/alertMsg/msg_withdrawal.png';
const walletIcon = '/images/components/alertMsg/wallet_green.png';

/* type mapping images */
const mapAlertIcon:any = {
  welcome: ImgMsgPerson,
  error: ImgMsgErrorRed,
  email: ImgMsgEmail,
  password: ImgMsgKey,
  withdrawal: ImgMsgWithdrawal, 
  guide: walletIcon
}

let progressTimeout: any = null;

const className = classnames.bind(style);
export default function MuseMessage() {
  const progressDom = useRef<HTMLDivElement | null>(null);
  const mainDom = useRef<HTMLDivElement | null>(null);
  const museMessageTarget = Common.Common.MessageTarget;
  const loginAuth = Common.UserData;

  const iconType = useMemo(() => {
    return museMessageTarget ? mapAlertIcon[museMessageTarget.type] : ImgMsgPerson;
  }, [museMessageTarget])

  const target = useMemo(() => {
    const messageTarget = museMessageTarget || {};
    const initState = Object.assign({}, {
      title: 'Title',
      message: 'Message Info',
      config: {},
      props: {}
    }, messageTarget)
    let kindState = { ...initState };
    const { kind = 0, messageType = 'error', privateQuery = {} } = initState.config;
    if (!kind) return initState;
    // 引导页通知用户1USDT到账dom
    if (kind === 10) {
      kindState = Object.assign(initState, {
        message: GuideSuccessTips
      })
    }
    else if (kind === 11) {
      kindState = Object.assign(initState, {
        message: UserLoginCompleteTips,
        props: { text: messageType, ...privateQuery }
      })
    }
    else{
      kindState = Object.assign(initState, {
        message: messageType
      })
    }
    return kindState;
  }, [museMessageTarget])

  const open = (): void => {
    const progress = progressDom?.current;
    if (!progress) return;
    progress.style.display = 'block';
    progress.style.animationDuration = '4s';
    if (progressTimeout) {
      clearTimeout(progressTimeout);
      progressTimeout = null;
    }
    progressTimeout = setTimeout(() => {
      progress.style.display = 'none';
      Common.Common.MessageTarget = null;
    }, 4 * 1000)
  }

  const setStyle = (addKey: string, removeKey: string, type: any): void => {
    const main = mainDom?.current;
    const progress = progressDom?.current;
    if (!main || !progress) return;
    main.classList.remove(className(removeKey));
    main.classList.add(className(addKey));
    if (!type) progress.style.display = 'none';
  }

  useEffect(() => {
    if (!museMessageTarget) {
      const main = mainDom?.current;
      const classNames = main?.className;
      if(classNames?.indexOf(className('show-box')) !== -1) {
        setStyle('hide-box', 'show-box', museMessageTarget);
      }
      return;
    }
    setStyle('show-box', 'hide-box', museMessageTarget);
    open();
  }, [museMessageTarget])
  return (
    <div ref={mainDom} className={className('message_main')}>
      <div className={className("message_container")}>
        <div className={className('message_body')}>
          <div className={className('message_left')}>
            <img src={iconType} alt={'Alert Message'} width={23} height={25} />
          </div>
          <div className={className('message_right')}>
            <div className={className('message_title')}>{target.title}</div>
            {target.config.kind >= 10 ? <target.message loginAuth={loginAuth} {...target.props} /> : <div className={className('message')}>{target.message}</div>}
          </div>
        </div>
        <div className={style['progress-box']}>
          <div ref={progressDom} className={style.progress}></div>
        </div>
      </div>
    </div>
  )
}
