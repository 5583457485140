import classnames from 'classnames/bind';
import style from './index.module.scss';
import { useRef } from 'react';

const className = classnames.bind(style);

interface PropsTarget {
    children?: any,
    closeWindown: any,
    titlestr?:string,
    cssStyle?: any,
 }

const CommonPop = (props: PropsTarget)=>{
    const refMask = useRef<HTMLDivElement | null>(null);
    const { children = <></>, closeWindown, cssStyle={}, titlestr="" } = props;
    return(
    <div className={className('bg')} style={{...cssStyle}} >
    <div ref={refMask} className={className('container')}>
    <div className={className('header_box')}>
      <div className={className('header_title')}>{titlestr}</div>
      <div className={className('close_icon')} onClick={closeWindown}>
        <img src={'/images/common/close_white.png'} alt="closeIcon"/>
      </div>
    </div>
    {children}
    </div>
    </div>
    )
}

export default CommonPop;