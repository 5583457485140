const CountryData:any = {
AF:["Afghanistan", "阿富汗"],
AL:["Albania", "阿尔巴尼亚"],
AD:["Andorra", "安道尔"],
AO:["Angola", "安哥拉"],
AI:["Anguilla", "安圭拉"],
AQ:["Antarctica", "南极洲"],
AG:["Ntigua and Barbuda", "安提瓜和巴布达"],
AR:["Argentina", "阿根廷"],
AM:["Armenia", "亚美尼亚"],
AW:["Aruba", "阿鲁巴"],
AU:["Australia", "澳大利亚"],
AT:["Austria", "奥地利"],
AZ:["Azerbaijan", "阿塞拜疆"],
AE:["United Arab Emirates", "阿拉伯联合酋长国"],
BS:["Bahamas","巴哈马"],
BH:["Bahrain","巴林"],
BD:["Bangladesh", "孟加拉"],
BB:["Barbados", "巴巴多斯"],
BY:["Belarus", "白俄罗斯"],
BZ:["Belize", "伯利兹"],
BE:["Belgium", "比利时"],
BJ:["Benin", "贝宁"],
BM:["Bermuda", "百慕大"],
BT:["Bhutan", "不丹"],
BO:["Bolivia", "玻利维亚"],
BA:["Bosnia Hercegovina", "波黑"],
BW:["Botswana", "博茨瓦纳"],
BV:["Bouvet Island", "布维岛"],
BR:["Brazil", "巴西"],
BN:["Brunei Darussalam", "文莱"],
BG:["Bulgaria", "保加利亚"],
BF:["Burkina Faso", "布其纳法索"],
BI:["Burundi", "布隆迪"],
CM:["Cameroon", "喀麦隆"],
CA:["Canada", "加拿大"],
CV:["Cape Verde,Republic of", "佛得角"],
CF:["The Central African Republic", "中非共和国"],
CL:["Chile", "智利"],
CN:["China", "中国"],
CX:["Christmas Island", "圣诞岛"],
CC:["COCOS Islands", "可可岛"],
CO:["Colombia", "哥伦比亚"],
CH:["tzerland", "瑞士"],
CG:["Congo", "刚果"],
CK:["Cook Island", "库克群岛"],
CR:["Costa rica", "哥斯达黎加"],
CI:["Lvory Coast", "象牙海岸"],
CU:["Cuba", "古巴"],
CY:["Cyprus", "塞浦路斯"],
CZ:["Czech Republic", "捷克共和国"],
DK:["Denmark", "丹麦"],
DJ:["Djibouti", "吉布提"],
DM:["Gominica", "多明哥"],
DE:["Grmany", "德国"],
DO:["Dominica", "多米尼加"],
DZ:["Algeria", "阿尔及利亚"],
EC:["Ecuador", "厄瓜多尔"],
EG:["Egypt", "埃及"],
EH:["West Sahara", "西撒哈拉"],
ES:["Spain", "西班牙"],
EE:["Estonia", "爱沙尼亚"],
ET:["Ethiopia", "埃塞俄比亚"],
FJ:["Fiji", "斐济"],
FK:["Falkland Islands", "福克兰群岛"],
FI:["Finland", "芬兰"],
FR:["France", "法国"],
FM:["Micronesia", "密克罗尼西亚"],
GA:["Gabon", "加蓬"],
GQ:["Equatorial Guinea", "赤道几内亚"],
GF:["French Guiana", "法属圭亚那"],
GM:["Gambia", "冈比亚"],
GE:["Georgia", "格鲁吉亚"],
GH:["Ghana", "加纳"],
GI:["Gibraltar", "直布罗陀"],
GR:["Greece", "希腊"],
GL:["Greenland", "格陵兰"],
GB:["United Kingdom", "英国"],
GD:["Grenada", "格林纳达"],
GP:["Guadeloupe", "瓜德罗普"],
GU:["Guam", "关岛"],
GT:["Guatemala", "危地马拉"],
GN:["Guinea", "几内亚"],
GW:["Guinea-Bissau", "几内亚比绍"],
GY:["Guyana", "圭亚那"],
HR:["Croatia", "克罗地亚"],
HT:["Haiti", "海地"],
HN:["Honduras", "洪都拉斯"],
HK:["Hong Kong", "中国香港"],
HU:["Hungary", "匈牙利"],
IS:["Iceland", "冰岛"],
IN:["India", "印度"],
ID:["Indonesia", "印度尼西亚"],
IR:["Iran", "伊朗"],
IQ:["Iraq", "伊拉克"],
IO:["British Indian Ocean Territory", "英联邦的印度洋领域"],
IE:["Ireland", "爱尔兰"],
IL:["Israel", "以色列"],
IT:["Italy", "意大利"],
JM:["Jamaica", "牙买加"],
JP:["Japan", "日本"],
JO:["Jordan", "约旦"],
KZ:["Kazakstan", "哈萨克斯坦"],
KE:["Kenya", "肯尼亚"],
KI:["Kiribati", "基里巴斯"],
KP:["North Korea", "朝鲜"],
KR:["Korea", "韩国"],
KH:["Cambodia", "柬埔寨"],
KM:["Comoros", "科摩罗"],
KW:["kuwait", "科威特"],
KG:["Kyrgyzstan", "吉尔吉斯斯坦"],
KY:["Cayman Islands", "开曼群岛"],
LA:["Laos", "老挝"],
LK:["Sri Lanka", "斯里兰卡"],
LV:["Latvia", "拉托维亚"],
LB:["Lebanon", "黎巴嫩"],
LS:["Lesotho", "莱索托"],
LR:["Liberia", "利比里亚"],
LY:["Libya", "利比亚"],
LI:["Liechtenstein", "列支敦士登"],
LT:["Lithuania", "立陶宛"],
LU:["Luxembourg", "卢森堡"],
LC:["St. Lucia", "圣卢西亚"],
MO:["Macao", "中国澳门"],
MG:["Malagasy", "马达加斯加"],
MW:["Malawi", "马拉维"],
MY:["Malaysia", "马来西亚"],
MV:["Maldives", "马尔代夫"],
ML:["Mali", "马里"],
MT:["Malta", "马耳他"],
MH:["Marshall Islands", "马绍尔群岛"],
MR:["Mauritania", "毛里塔尼亚"],
MU:["Mauritius", "毛里求斯"],
MX:["Mexico", "墨西哥"],
MD:["Moldova", "摩尔多瓦"],
MC:["Monaco", "摩纳哥"],
MN:["Mongolia", "蒙古"],
MS:["Montserrat", "蒙特塞拉特"],
MA:["Morocco", "摩洛哥"],
MZ:["Mozambique", "莫桑比克"],
MM:["Burma", "缅甸"],
MP:["Northern Mariana Islands", "北马里亚那群岛"],
NA:["Namibia", "纳米比亚"],
NR:["Naura", "瑙鲁"],
NP:["Nepal", "尼泊尔"],
NL:["Netherlands", "荷兰"],
NT:["Neutral Zone", "中立区"],
NC:["New Caledonia", "新喀里多尼亚"],
NZ:["New Zealand", "新西兰"],
NI:["Nicaragua", "尼加拉瓜"],
NE:["Niger", "尼日尔"],
NG:["Nigeria", "尼日利亚"],
NU:["Niue", "纽埃"],
NF:["Norfolk Island", "诺福克岛"],
NO:["Norway", "挪威"],
OM:["Oman", "阿曼"],
PK:["Pakistan", "巴基斯坦"],
PF:["French Polynesia", "法属玻里尼西亚"],
PW:["Palau", "帕劳"],
PA:["Panama", "巴拿马"],
PG:["Papua,Territory of", "巴布亚新几内亚"],
PY:["Paraguay", "巴拉圭"],
PE:["Peru", "秘鲁"],
PH:["Philippines", "菲律宾"],
PN:["Pitcairn Islands", "皮特开恩群岛"],
PL:["Poland", "波兰"],
PT:["Portugal", "葡萄牙"],
PR:["Puerto Rico", "波多黎各（美）"],
QA:["Qatar", "卡塔尔"],
RO:["Romania", "罗马尼亚"],
RU:["Russia", "俄罗斯联邦"],
RW:["Rwanda", "卢旺达"],
SV:["El Salvador", "萨尔瓦多"],
SH:["St.Helena", "圣赫勒那"],
SM:["San Marino", "圣马力诺"],
ST:["Sao Tome and Principe", "圣多美与普林西比"],
SA:["Saudi Arabia", "沙特阿拉伯"],
SN:["Senegal", "塞内加尔"],
SC:["Seychelles", "塞舌尔"],
SL:["Sierra leone", "塞拉利昂"],
SG:["Singapore", "新加坡"],
SK:["Slovakia", "斯洛伐克"],
SI:["Slovene", "斯洛文尼亚"],
SB:["Solomon Islands", "所罗门群岛"],
SO:["Somali", "索马里"],
SD:["Sudan", "苏丹"],
SR:["Surinam", "苏里南"],
SZ:["Swaziland", "斯威士兰"],
SE:["Sweden", "瑞典"],
SY:["Syria", "叙利亚"],
TD:["Chad", "乍得"],
TF:["French Southern Territoties", "法属南方领土"],
TW:["Taiwan", "中国台湾"],
TJ:["Tsjikistan", "塔吉克斯坦"],
TZ:["Tanzania", "坦桑尼亚"],
TH:["Thailand", "泰国"],
TG:["Togo", "多哥"],
TK:["Tokela", "托克劳"],
TO:["Tonga", "汤加"],
TT:["Trinidad and Tobago", "特立尼达和多巴哥"],
TN:["Tunisia", "突尼斯"],
TR:["Turkey", "土尔其"],
TP:["East Timor", "东帝"],
TM:["Turkomanstan", "土库曼斯坦"],
TC:["Turks and Caicos Islands", "特克斯和凯科斯群岛"],
TV:["Tuvalu", "图瓦卢"],
UG:["Uganda", "乌干达"],
UA:["Ukiain", "乌克兰"],
UK:["England", "英国（正式代码为GB）"],
US:["America", "美国"],
UY:["uruguay", "乌拉圭"],
UZ:["Uzbekstan", "乌兹别克斯坦"],
VA:["Vatican", "梵蒂冈"],
VE:["Venezuela", "委内瑞拉"],
VN:["Vietnam", "越南"],
VG:["Virgin Islands(British)", "不列颠岛（英）"],
VI:["Vigin Islands(U.S.)", "不列颠岛（美）"],
WS:["Western Samoa", "西萨摩亚"],
YE:["Yemen", "也门"],
YU:["Yugoslavia", "南斯拉夫"],
ZA:["South Africa", "南非"],
ZR:["Zaire", "扎伊尔"],
ZM:["Zambia", "赞比亚"],
ZW:["Zimbabwe", "津巴布韦"],
}

export default CountryData;