/// 验证码
import Common from "./Common";
import { HttpNetPost } from "../Net/HttpNetMg";
import NetProtocol, { VerifyCode } from "../Net/NetProtocol";


export default class VerificationCode{
    private static instance:any = null;
    public static get Instance(): VerificationCode{
        if(this.instance == null){
            this.instance = new VerificationCode();
        }
        return this.instance;
    }

    public DownTimeNum = 0;

    constructor(){
        
    }
    
    private SendCodeDownTime(){
        if(this.DownTimeNum>0){
            setTimeout(() => {
                this.DownTimeNum--;
                Common.Code = this;
                this.SendCodeDownTime();
            }, 1000);
        }
    }

    // 请求邮箱验证码
    public async SendEmailCode(ndata:VerifyCode){
        const resData:NetProtocol = await HttpNetPost("/getcode", ndata, true);
        if(resData.code === 0){
            Common.Common.PopTips = 'Successfully sent email verification code!';
            this.DownTimeNum = 60;
            this.SendCodeDownTime();
        }
    }
}