import { HttpWebGet } from "../Net/HttpNetMg";
import Common from "./Common";

export default class ProductData{
    private static instance: ProductData;
    public static get Instance():ProductData{
        if(!this.instance){
            this.instance = new ProductData();
        }
        return this.instance;
    }
    private isLoaded:boolean;
    private products:any;
    private menus:any;
    constructor(){
        this.products = null;
        this.isLoaded = false;
    }

    public get Products():any{
        return this.products;
    }

    public get Menus():any{
        return this.menus;
    }

    public get IsLoaded():boolean{
        return this.isLoaded;
    }

    public async GetProduct(){
        if(!this.products){
            const {status, text} = await HttpWebGet('/product/product.json');
            if(status === 200){
                this.products = JSON.parse(text);
                if(this.menus){
                  this.isLoaded = true;
                }
                Common.Product = this;
            }
        }
    }

    public GetProductById(productid:string){
        if(this.products){
            for (let index = 0; index < this.products.length; index++) {
                if(this.products[index].ProductId === productid){
                    return this.products[index];
                }
            }
        }
        return null;
    }

    public async GetMenus(){
        if(!this.menus){
            const {status, text} = await HttpWebGet('/product/menu.json');
            if(status === 200){
                this.menus = JSON.parse(text);
                if(this.products){
                  this.isLoaded = true;
                }
                Common.Product = this;
            }
        }
    }
}