import { useState } from "react";
import classnames from 'classnames/bind';
import Common from "../../Common/Common";
import CommonPop from "../CommonPop";
import Switch from "./Switch";
import lstyle from './index.module.scss';
import LoginLayout from "./LoginLayout";
import RegisterLayout from "./RegisterLayout";

const className = classnames.bind(lstyle);

const LoginPop = ()=>{
    const [type, updateState] = useState(1);
    const close = () => {
        Common.Common.ShowLogin = false;
    }
    return(
        <CommonPop closeWindown={close}>
            <section className={className('main')}>
               <div className={className('block')}></div>
               <Switch type={type} onChange={(value: number) => updateState(value) } />
               <div className={className('block_second')}></div>
               {type === 1
                 ? <LoginLayout/>
                 : <RegisterLayout/>
               }
            </section>
        </CommonPop>
    )
}

export default LoginPop;