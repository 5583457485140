import CommonPop from "../../CommonPop";
import classnames from 'classnames/bind';
import style from './index.module.scss';
import SignNameInput from "../SignNameInput";
import InputVerify, { VerifyData } from "../../../components/InputVerify";
import useSignNameInput from "../hooks/useSignNameInput";
import { ReSetPsdProto } from "../../../Net/NetProtocol";
import UserInfoData from "../../../Common/UserInfoData";

const className = classnames.bind(style)

interface ForgetPwdProps {
    hidePop: () => void
}
const ForgetPwd = ({ hidePop = () => ({}) }: ForgetPwdProps)=>{
    const { nameInput, invalidName, onUpdateNameInput, isValidNameInput, isValidEmailInput} = useSignNameInput();
    const onRecoverPwd = ()=>{
        if(isValidNameInput()){
            let authcode = VerifyData.GetAuthCode();
            if(authcode){
                const {email,password} = nameInput;
                let ndata:ReSetPsdProto = new ReSetPsdProto();
                ndata.account = email;
                ndata.password = password;
                ndata.verifycode = authcode;
                UserInfoData.Instance.UserReSetPwd(ndata);
            }
        }
    }
    return(
        <>
        <CommonPop closeWindown={hidePop} titlestr="Forgot password">
            <div className={className('pop_content_wrap')}>
                <SignNameInput nameInput={nameInput} onUpdateNameInput={onUpdateNameInput} invalidName={invalidName}/>
                <InputVerify verifytype={1} nameInput={nameInput} isValidEmailInput={isValidEmailInput}/>
                <button
                  className={className('btn_start')}
                  onClick={() => onRecoverPwd()}
                >
                  {"Reset Password"}
                </button>
            </div>
        </CommonPop>
        </>
    )
}

export default ForgetPwd;