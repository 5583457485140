import React,{Suspense} from 'react';
import { HashRouter, Route, Routes } from "react-router-dom";
import Loading from '../Loading'

let Home = React.lazy(()=>import('../Home'));
let Product = React.lazy(()=>import('../Product'));
let Services = React.lazy(()=>import('../Services'));
let UserChatList = React.lazy(()=>import('../Services/UserChatList'));
let Editor = React.lazy(()=>import('../Editor'));

export default function RouterView(){
    return (
      <HashRouter>
        <Routes>
            <Route path="/" element={<Suspense fallback={<Loading/>}><Home/></Suspense>}></Route>
            <Route path="/Home" element={<Suspense fallback={<Loading/>}><Home/></Suspense>}></Route>
            <Route path="/Product/:product" element={<Suspense fallback={<Loading/>}><Product/></Suspense>}></Route>
            <Route path="/Services" element={<Suspense fallback={<Loading/>}><Services/></Suspense>}></Route>
            <Route path="/UserChats" element={<Suspense fallback={<Loading/>}><UserChatList/></Suspense>}></Route>
            <Route path="/Editor" element={<Suspense fallback={<Loading/>}><Editor/></Suspense>}></Route>
        </Routes>
      </HashRouter>
    );
};