import Input from "../../../components/Input";
import ComState from "../../../Api/ComState";
import classnames from 'classnames/bind';
import style from './index.module.scss';
import { validContainsEnglishNumbers, validContainsSymbols, validFirstWordCapital, validPassword, validTextLength } from "../../../Api/validation";
import InputHintPop from "../../../components/InputHintPop";
import Common from "../../../Common/Common";
import { ChangePsdProto } from "../../../Net/NetProtocol";
import { useEffect } from "react";
const className = classnames.bind(style);
const hintType:any = {
    oldPwd: {left: 0, top: '32%'},
    newPwd: {left: 0, top: '62%'},
    confirmPwd: {left: 0, top: '41%'},
}
const star_icon = '/images/common/star_icon.png';
const initialState = {
    pwdFrom: {        // 密码输入框内容
      oldPwd: '',
      newPwd: '',
      confirmPwd: ''
    },
    invalidPasswordValid: {     // input输入错误提示
      oldPwd: {flag: false, errMsg: ''},
      newPwd: {flag: false, errMsg: ''},
      confirmPwd: {flag: false, errMsg: ''}
    },
    pwdHintPopState: false,     // 密码提示弹窗显示/隐藏状态
    hintPopStyle: {},           // 提示弹窗样式
    pwdHintState: {             // 提示内容状态
      charactersState: false,     // 8-20 个字符，成功則顯示打勾否則打叉
      inputRulesState: false,     // 包含英文或数字且不能有特殊符號，成功則顯示打勾否則打叉
    },
    currentInputType: '',       // 当前输入类型
    showPwdState: false,        // 密码区域显示状态  PS: 默认显示
}

const Modifypsd = ()=>{
    const { state, updateState } = ComState(initialState);
    const {
        pwdFrom,
        invalidPasswordValid,
        pwdHintPopState,
        hintPopStyle,
        pwdHintState,
        currentInputType,
        showPwdState
    } = state.mdata;

    /** function */
  // 更新input
  const updatePwdFrom = (type: string, value: string) => {
    const temp:any = {}
    temp[type] = value;
    updateState({pwdFrom: Object.assign({}, pwdFrom, temp)});
  }
  // 显示/隐藏提示弹窗
  const openHint = (type: string, bool = true) => {
    const valid = validPassword(pwdFrom[type]);     // 密码验证
    updateState({
      hintPopStyle: hintType[type],
      pwdHintPopState: !valid && bool
    })
  }
  // 获取输入框焦点
  const onFocusBack = (type: string) => {
    updateState({currentInputType: type});
    initFaildHint(type);
  }
  // 隐藏当前错误提示
  const initFaildHint = (type: string) => {
    const temp:any = {};
    temp[type] = {flag: false, errMsg: ''}
    updateState({invalidPasswordValid: Object.assign({}, invalidPasswordValid, temp)})
  }
  // 密码验证失败文案
  const onPasswordValidText = (password: string): string => {
    if (password === '') {
        // 檢查密碼是否為空
        return "Password can't be empty.";
      } else if (!validTextLength(password)) {
        // 檢查長度是否為8-20字元
        return "Please Enter 8-20 Characters";
      } else if (!validContainsSymbols(password)) {
        // 檢查是否包含特殊符號
        return "The password cannot contain special symbols.";
      } else if (!validContainsEnglishNumbers(password)) {
        // 檢查是否包含英文及數字
        return "Password must contain English and numbers.";
      } else if (!validFirstWordCapital(password)) {
        // 檢查第一個字元是否為大寫英文字母
        return "First with capitalization.";
      } else {
        return 'Password must contain English in small caps'
      }
  }
  // 表单错误提示
  const validAllFromItem = () => {
    updateState({
      invalidPasswordValid: {
        oldPwd: {
          flag: !validPassword(pwdFrom.oldPwd),
          errMsg: onPasswordValidText(pwdFrom.oldPwd)
        },
        newPwd: {
          flag: !validPassword(pwdFrom.newPwd),
          errMsg: onPasswordValidText(pwdFrom.newPwd)
        },
        confirmPwd: {
          flag: !validPassword(pwdFrom.confirmPwd),
          errMsg: onPasswordValidText(pwdFrom.confirmPwd)
        }
      }
    })
  }
  // 表单验证
  const validAllFrom = () => {
    const validMap = [
      validPassword(pwdFrom.oldPwd),
      validPassword(pwdFrom.newPwd),
      validPassword(pwdFrom.confirmPwd),
    ]
    return validMap.every(item => item);
  }
  // 提交修改密码请求
  const onSubmitSetPwd = () => {
    if(!validAllFrom()) {
      validAllFromItem();
      return;
    }
    // 新密码不能与旧密码相同
    if(pwdFrom.oldPwd === pwdFrom.newPwd) {
      updateState({
        invalidPasswordValid: Object.assign({}, invalidPasswordValid, {
          newPwd: { flag: true, errMsg: "New password can't be the same as old password." }
        })
      })
      return;
    }
    // 新密码不能与确认密码不同
    if(pwdFrom.newPwd !== pwdFrom.confirmPwd) {
      updateState({
        invalidPasswordValid: Object.assign({}, invalidPasswordValid, {
          confirmPwd: { flag: true, errMsg: "Confirm password does not match new password." }
        })
      })
      return;
    }
    let ndata = new ChangePsdProto();
    ndata.account = Common.UserData.account;
    ndata.oldpassword = pwdFrom.oldPwd;
    ndata.newpassword = pwdFrom.newPwd;
    Common.UserData.UserChangePwd(ndata);
  }

  useEffect(()=>{
    if(Common.UserData.actype === 1){
      updatePwdFrom('oldPwd', Common.UserData.account);
    }
  },[Common.UserData.actype])

    return(
        <div className={className('safety_center_wrap')}>
        {/* 修改密码 */}
        <div className={className("safety_center_item_wrap")}>
          <div className={className('safety_center_item_title')} onClick={() => updateState({showPwdState: !showPwdState})}>
            <div className={className('left_safety_icon')}>
              <img src={star_icon} alt="star_icon" />
            </div>
            <div className={className('title_text')}>{"Change password"}</div>
            <div className={className('title_right_arrow', {title_right_arrow_up: showPwdState})}></div>
          </div>
          <div className={className('safety_center_content_box')} style={{height: showPwdState ? 'auto' : '0'}}>
            <div className={className('setpwd_input_wrap')}>
              {/* 旧密码 */}
              <Input
                required
                mode="password"
                label={"Old password"}
                labelStyle={{color: '#97A3B0'}}
                type="password"
                value={pwdFrom.oldPwd}
                changeInputValue={value => updatePwdFrom('oldPwd', value)}
                invalidFlag={invalidPasswordValid.oldPwd.flag}
                invalidMsg={invalidPasswordValid.oldPwd.errMsg}
                placeholder={"Old password"}
                focusInput={() => onFocusBack('oldPwd')}
                blurInput={() => openHint('oldPwd', false)}
                disabled={Common.UserData.actype === 1}
              />
              {/* 新密码 */}
              <Input
                required
                mode="password"
                label={"New password"}
                labelStyle={{color: '#97A3B0'}}
                type="password"
                value={pwdFrom.newPwd}
                changeInputValue={value => updatePwdFrom('newPwd', value)}
                invalidFlag={invalidPasswordValid.newPwd.flag}
                invalidMsg={invalidPasswordValid.newPwd.errMsg}
                placeholder={"Update password"}
                focusInput={() => onFocusBack('newPwd')}
                blurInput={() => openHint('newPwd', false)}
              />
              {/* 确认密码 */}
              <Input
                required
                mode="password"
                label={"Confirm password"}
                labelStyle={{color: '#97A3B0'}}
                type="password"
                value={pwdFrom.confirmPwd}
                changeInputValue={value => updatePwdFrom('confirmPwd', value)}
                invalidFlag={invalidPasswordValid.confirmPwd.flag}
                invalidMsg={invalidPasswordValid.confirmPwd.errMsg}
                placeholder={"Confirm password"}
                focusInput={() => onFocusBack('confirmPwd')}
                blurInput={() => openHint('confirmPwd', false)}
              />
              {pwdHintPopState && <InputHintPop hintState={pwdHintState} type={'password'} wrapStyle={hintPopStyle}/>}
            </div>
            <button className={className('setpwd_submit_btn')} onClick={onSubmitSetPwd}>Submit</button>
          </div>
        </div>
        </div>
    )
}

export default Modifypsd;