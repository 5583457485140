import { useState } from "react";

export default function ComState(stateObj: any){
    const [state, comSetState] = useState({mdata: stateObj});
    const updateState = (setData:any)=>{
        let key: keyof any;
        let ndata:any = state.mdata;
        for (key in setData) {
            ndata[key] = setData[key];
        }
        comSetState({mdata: ndata});
    }
    return {state, updateState};
}