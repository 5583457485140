import ComState from "../../Api/ComState";
import Common from "../../Common/Common";
import CommonPop from "../../PopView/CommonPop";
import Input from "../../components/Input";
import classnames from 'classnames/bind';
import style from './index.module.scss';
import { PayPalAddTrackingProto } from "../../Net/NetProtocol";
import ShowAddress from "../ShowAddress";
import { useMemo } from "react";
const className = classnames.bind(style);

const initialState = {
    trackFrom: {        // 密码输入框内容
      tracktype: '',
      tracknum: ''
    },
    invalidValid: {     // input输入错误提示
      tracktype: {flag: false, errMsg: ''},
      tracknum: {flag: false, errMsg: ''}
    }
}

const AddTracking = ()=>{
    const sorders = Common.Sorders;
    const { state, updateState } = ComState(initialState);
    const {
        trackFrom,
        invalidValid
    } = state.mdata;
    
    const closeFunc = ()=>{
        sorders.SelectPid = "";
    }

    const onUpdateInput = (type: string, value: string) => {
        const temp:any = {}
        temp[type] = value;
        updateState({trackFrom: Object.assign({}, trackFrom, temp)});
    }

    const userOrder = useMemo(()=>{
        return sorders.Products.find(item=>item.orderid === sorders.SelectPid);
    }, [sorders.SelectPid]);

    const onConfirm = ()=>{
        if(!trackFrom.tracktype || trackFrom.tracktype === ""){
            Common.Common.PopTips = "请填写快递商类型";
            return;
        }
        if(!trackFrom.tracknum || trackFrom.tracknum === ""){
            Common.Common.PopTips = "请填写快递运单号";
            return;
        }
        const nitem = sorders.Products.find(item=>item.orderid === sorders.SelectPid);
        if(nitem){
            let tracking = new PayPalAddTrackingProto();
            tracking.account = Common.UserData.account;
            tracking.orderid = nitem.orderid;
            tracking.timed = nitem.timed;
            tracking.carrier = trackFrom.tracktype;
            tracking.tracking_number = trackFrom.tracknum;
            Common.Sorders.ProAddTracking(tracking);
        }
    }

    return(
        <CommonPop closeWindown={closeFunc} titlestr={"填写运单号"}>
            <div className={className('contentbox')}>
            {userOrder && <ShowAddress useract={userOrder.account}/>}
            <div className={className('orderid')}>
                {"OrderId: " + sorders.SelectPid}
            </div>
            <Input
              required
              mode='selection'
              label={"快递运营商"}
              value={trackFrom.tracktype}
              changeInputValue={value => onUpdateInput('tracktype', value)}
              invalidFlag={invalidValid.tracktype.flag}
              invalidMsg={invalidValid.tracktype.errMsg}
              placeholder={"快递运营商"}
              inputBlockStyle={{width: '100%'}}
              options={["CN_CHINA_POST_EMS","YANWEN"]}
            />

            <Input
              required
              mode="email"
              label={"快递单号"}
              value={ trackFrom.tracknum }
              changeInputValue={value => onUpdateInput('tracknum', value)}
              invalidFlag={invalidValid.tracknum.flag}
              invalidMsg={invalidValid.tracknum.errMsg}
              placeholder={"快递单号"}
              inputBlockStyle={{width: '100%'}}
            />

            <div className={className('btn_start')} onClick={onConfirm}>
                <span>{"Confirm"}</span>
            </div>
            </div>
        </CommonPop>
    )
}

export default AddTracking;