import { NetComparable, IComparString, IComparArray, IComparNumber } from "./NetComparable";
import {NetByte} from "./NetByte";

export module NetCom{
    export class Float64 extends IComparNumber
    {
        public fromValue(value2: number, unsigned: Boolean){
            this.value = value2;
        }
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readFloat64();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            buffers.writeFloat64(this.value);
        }
    }

    export class Float32 extends IComparNumber
    {
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readFloat32();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            buffers.writeFloat32(this.value);
        }
    }
    
    export class Int64 extends IComparNumber
    {
        private static TWO_PWR_16_DBL = 1 << 16;
        private static TWO_PWR_32_DBL = Int64.TWO_PWR_16_DBL*Int64.TWO_PWR_16_DBL;
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            var low:number;
			var high:number;
			if(NetByte.IsLittleEndian)
			{
				low = buffers.readInt32();
				high = buffers.readInt32();
			}else{
				high = buffers.readInt32();
				low = buffers.readInt32();
			}
			this.value = high * Int64.TWO_PWR_32_DBL + (low >>> 0);
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            let low: number;
            let high: number;
            let isminus: boolean = false;
            if(this.value < 0){
                this.value = -this.value - 1;
                isminus = true;
            }
            low = (this.value % Int64.TWO_PWR_32_DBL) | 0;
            high = (this.value / Int64.TWO_PWR_32_DBL) | 0;// 用位运算this.value>>32 & 0xFFFFFFFF 会越界丢失数据
            if(isminus){
                low = low ^ 0xFFFFFFFF;
                high = high ^ 0xFFFFFFFF;
            }
			if(NetByte.IsLittleEndian){
				buffers.writeUint32(low);
				buffers.writeUint32(high);
			}else{
				buffers.writeUint32(high);
				buffers.writeUint32(low);
			}
        }
    }

    export class Uint64 extends IComparNumber
    {
        private static TWO_PWR_16_DBL = 1 << 16;
        private static TWO_PWR_32_DBL = Uint64.TWO_PWR_16_DBL*Uint64.TWO_PWR_16_DBL;
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            let low:number;
			let high:number;
			if(NetByte.IsLittleEndian)
			{
				low = buffers.readUint32();
				high = buffers.readUint32();
			}else{
				high = buffers.readUint32();
				low = buffers.readUint32();
			}
			this.value = ((high >>> 0) * Uint64.TWO_PWR_32_DBL) + (low >>> 0);
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            if(this.value < 0){
                this.value = 0;
            }
            let low = (this.value % Uint64.TWO_PWR_32_DBL) | 0;
            let high = (this.value / Uint64.TWO_PWR_32_DBL) | 0;// 用位运算this.value>>32 & 0xFFFFFFFF 会越界丢失数据
			if(NetByte.IsLittleEndian){
				buffers.writeUint32(low);
				buffers.writeUint32(high);
			}else{
				buffers.writeUint32(high);
				buffers.writeUint32(low);
			}
        }
    }

    export class Int32 extends IComparNumber
    {
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readInt32();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            buffers.writeInt32(this.value);
        }
    }

    export class Uint32 extends IComparNumber
    {
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readUint32();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            buffers.writeUint32(this.value);
        }
    }

    export class Int16 extends IComparNumber
    {
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readInt16();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            buffers.writeInt16(this.value);
        }
    }

    export class Uint16 extends IComparNumber
    {
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readUint16();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            buffers.writeUint16(this.value);
        }
    }

    export class Byte extends IComparNumber
    {
        constructor(d: number = 0)
        {
            super(d);
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readUint8();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            buffers.writeUint8(this.value);
        }
    }

    export class String extends IComparString
    {
        constructor()
        {
            super("");
        }
        public readData(buffers: NetByte): any{
            this.value = buffers.readUTFString();
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            if(this.value){
                buffers.writeUTFString(this.value);
            }
            else{
                buffers.writeUint16(0);
            }
        }
    }

    export class ClassTb extends IComparArray
    {
        private aType: any;
        constructor(atype: any)
        {
            super(null);
            this.aType = atype;
        }
        public readData(buffers: NetByte): any
        {
            this.value = Deserialize(this.aType, buffers);
            return this.value;
        }
        public writeData(buffers: NetByte): void
        {
            Serialize(this.aType, this.value, buffers);
        }
    }

    export class ArrayNumber extends IComparArray
    {
        private aType: IComparNumber;
        private length: number;
        constructor(atype: any, length: number = 0)
        {
            super(null);
            this.aType = atype;
            this.length = length;
        }
        public readData(buffers: NetByte): any
        {
            this.length = this.length <= 0? buffers.readUint16(): this.length;
            let dt = new Array();
            for (let index = 0; index < this.length; index++)
            {
                this.aType.readData(buffers);
                dt[index] = this.aType.value;
            }
            this.value = dt;
            return this.value;
        }
        public writeData(buffers: NetByte): void{
            if(this.value.length > 0)
            {
                buffers.writeUint16(this.value.length);
                for (let index = 0; index < this.value.length; index++) {
                    this.aType.value = this.value[index];
                    this.aType.writeData(buffers);
                }
            }
            else{
                buffers.writeUint16(0);
            }
        }
    }

    export class ArrayTable extends IComparArray
    {
        private aType: any;
        private length: number;
        constructor(atype: any, length: number = 0)
        {
            super(null);
            this.aType = atype;
            this.length = length;
        }
        public readData(buffers: NetByte): any
        {
            this.value = DeserializeList(this.aType, buffers, this.length);
            return this.value;
        }
        public writeData(buffers: NetByte): void
        {
            SerializeList(this.value, this.aType, buffers);
        }
    }

    //序列化
    export function Serialize(stobj: any, obj: any, buffs: NetByte) : NetByte
    {
        let key: keyof any;
        for (key in stobj) {
            stobj[key].value = obj[key];
            let dt = stobj[key] as NetComparable;
            dt.writeData(buffs);
        }
        return buffs;
    }
    //序列化列表
    export function SerializeList(objs: any, aType: any, buffs: NetByte) : NetByte
    {
        if(objs != null && objs.length > 0){
            buffs.writeUint16(objs.length);
            for (let index = 0; index < objs.length; index++) {
                Serialize(aType, objs[index], buffs);
            }
        }
        else{
            buffs.writeUint16(0);
        }
        return buffs;
    }

    //反序列化
    export function Deserialize(obj: any, buffs: NetByte): any
    {
        let key: keyof any;
        let nobj = new obj();
        for (key in obj)
        {
            let dt = obj[key] as NetComparable;
            nobj[key] = dt.readData(buffs);
        }
        return nobj;
    }

    //反序列化列表
    export function DeserializeList(obj: any, buffs: NetByte, length: number = 0): any
    {
        let lenth = length <= 0? buffs.readUint16(): length;
        let nobjs = new Array();
        for (let index = 0; index < lenth; index++) {
            nobjs[index] = Deserialize(obj, buffs);
        }
        return nobjs;
    }

    export class BaseMessage{
        constructor(){

		}
        public static ReadFrom(bytes: NetByte): any
        {
            return Deserialize(this, bytes);
		}
		public static WriteFrom(nobj: any, buffs?: NetByte): NetByte{
			return Serialize(this, nobj, buffs||new NetByte());
		}
        public static LWriteFrom(nobj: any, proto: number): NetByte{
            let msg: NetByte = new NetByte();
            msg.writeUint16(0);
            msg.writeUint8(proto);
            Serialize(this, nobj, msg);
            msg.pos = 0;
            msg.writeUint16(msg.length-2);
            return msg;
        }
    }

    // 使用静态类减少内存开销
    export class bit
    {
        public static long: Int64 = new Int64();
        public static ulong: Uint64 = new Uint64();
        public static int: Int32 = new Int32();
        public static uint: Uint32 = new Uint32();
        public static short: Int16 = new Int16();
        public static ushort: Uint16 = new Uint16();
        public static double: Float64 = new Float64();
        public static float: Float32 = new Float32();
        public static byte: Byte = new Byte();
        public static string: String = new String();
        public static longArray: ArrayNumber = new ArrayNumber(this.long);
        public static ulongArray: ArrayNumber = new ArrayNumber(this.ulong);
        public static intArray: ArrayNumber = new ArrayNumber(this.int);
        public static uintArray: ArrayNumber = new ArrayNumber(this.uint);
        public static shortArray: ArrayNumber = new ArrayNumber(this.short);
        public static ushortArray: ArrayNumber = new ArrayNumber(this.ushort);
        public static doubleArray: ArrayNumber = new ArrayNumber(this.double);
        public static floatArray: ArrayNumber = new ArrayNumber(this.float);
        public static byteArray: ArrayNumber = new ArrayNumber(this.byte);
    }
}