import { useState } from "react";
import CommonData from "./CommonData";
import ProductData from "./ProductData";
import ShopCardData from "./ShopCardData";
import UserInfoData from "./UserInfoData";
import VerificationCode from "./VerificationCode";
import OrderRecordData from "./OrderRecordData";
import ServerOrders from "./ServerOrders";
import MgChatData from "./MgChatData";

function NewState(stateObj: any){
    const [comstate, comSetState] = useState(stateObj);
    return {comstate, comSetState};
}

enum tpState{
    common = 0,
    product, // 产品配置
    shopcard,// 购物车
    user, // 玩家个人信息
    code, //验证码
    orders,//订单记录
    sorders,//服务器订单
    chat, //聊天
    length, // 枚举长度
}

export default class Common{
    public static Comstates: any[];
    public static ComSetStates: any[];

    public static Init(){
        this.Comstates = [];
        this.ComSetStates = [];
        for (let index = 0; index < tpState.length; index++) {
            const {comstate, comSetState} = NewState({Data:null});
            this.Comstates[index] = comstate;
            this.ComSetStates[index] = comSetState;
        }
    }

    public static get Common(): CommonData{
        return CommonData.Instance;
    }

    public static set Common(v: CommonData){
        this.ComSetStates[tpState.common]({Data: v});
    }

    public static get Product(): ProductData{
        return ProductData.Instance;
    }

    public static set Product(v: ProductData){
        this.ComSetStates[tpState.product]({Data: v});
    }

    public static get ShopCard(): ShopCardData{
        return ShopCardData.Instance;
    }

    public static set ShopCard(v: ShopCardData){
        this.ComSetStates[tpState.shopcard]({Data: v});
    }

    public static get UserData(): UserInfoData{
        return this.Comstates[tpState.user]?.Data;
    }

    public static set UserData(v: any){
        const userinfodt = v? UserInfoData.Instance: null;
        if(userinfodt){
            userinfodt.InitData(v, userinfodt);
        }
        else{
            UserInfoData.Instance.LoginQuit();
            OrderRecordData.Instance.ClearProducts();
            MgChatData.Instance.Quit();
        }
        this.ComSetStates[tpState.user]({Data: userinfodt});
    }

    public static get Code(): VerificationCode{
        return VerificationCode.Instance;
    }

    public static set Code(v:VerificationCode){
        this.ComSetStates[tpState.code]({Data: v});
    }

    public static get Orders(): OrderRecordData{
        return OrderRecordData.Instance;
    }

    public static set Orders(v:OrderRecordData){
        this.ComSetStates[tpState.orders]({Data: v});
    }

    public static get Sorders(): ServerOrders{
        return ServerOrders.Instance;
    }

    public static set Sorders(v:ServerOrders){
        this.ComSetStates[tpState.sorders]({Data: v});
    }

    public static get Chat(): MgChatData{
        return MgChatData.Instance;
    }

    public static set Chat(v:MgChatData){
        this.ComSetStates[tpState.chat]({Data: v});
    }
}