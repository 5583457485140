import Common  from './Common/Common';
import mainsty from './styles/index.module.css';
import Loading from './Loading';
import IframePop from './PopView/IframePop';
import ServicePop from './PopView/ServicePop';
import TipsPop from './PopView/TipsPop';
import RouterView from './RouterView';
import ShoppingCard from './PopView/ShoppingCard';
import './styles/globals.css';
import LoginPop from './PopView/LoginPop';
import MuseMessage from './components/MuseMessage';
import OrderRecord from './PopView/OrderRecord';
import { PayPalScriptProvider, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';
import Account from './PopView/Account';
import AddTracking from './Services/AddTracking';
import RePayOrder from './PopView/OrderRecord/RePayOrder';
import Address from './PopView/Address';
import ChatPop from './PopView/ChatPop';
import {isMobile} from 'react-device-detect';
const initialOptions: ReactPayPalScriptOptions = {
  clientId:process.env.PAYPAL_CLIENT_ID||""
};

function addMetaTag(metaName:string, metaContent:string) {
  var meta = document.createElement('meta');
  meta.setAttribute('name', metaName);
  meta.setAttribute('content', metaContent);
  document.getElementsByTagName('head')[0].appendChild(meta);
}

function App() {
  if(isMobile){
    addMetaTag("viewport", "width=device-width, initial-scale=0.8, minimum-scale=0.8, maximum-scale=0.8, user-scalable=no");
  }
  else{
    addMetaTag("viewport", "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no");
  }
  
  Common.Init();
  const CommonData = Common.Common;
  const shopcard = Common.ShopCard;
  const chatmg = Common.Chat;
  const orders = Common.Orders;
  return (
    <div className={mainsty.main}>
      <RouterView></RouterView>
      <MuseMessage/>
      {chatmg.SelectChat!==undefined&&chatmg.SelectChat!==null&&<ChatPop userchat={chatmg.SelectChat}/>}
      {CommonData.ShowIframe&&<IframePop/>}
      {CommonData.ShowAddress&&<Address/>}
      {CommonData.ShowService&&<ServicePop/>}
      {shopcard.IsShow&&<ShoppingCard/>}
      {orders.IsShow&&<OrderRecord/>}
      {orders.SelectPay&&<RePayOrder/>}
      {CommonData.ShowLogin&&<LoginPop/>}
      {CommonData.ShowAccount&&<Account/>}
      {Common.Sorders.ShowTrack&&<AddTracking/>}
      {CommonData.ShowLoading&&<Loading/>}
      {CommonData.PopTips!==''&& <TipsPop/>}
      <PayPalScriptProvider options={initialOptions}/>
    </div>
  );
}

export default App;
