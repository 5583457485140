import { useEffect } from "react";
import style from './index.module.scss';
import LoginVerify from "./LoginVerify";
import Common from "../../../Common/Common";
declare const window:any;
declare const google:any;
const clientId = '910730998909-89q27ram734hfajmjk6a8ndofnv7lsfn.apps.googleusercontent.com';

interface PoropsInter{
  LoginBack:(logindt?:any)=>void
}

function GoogleLogin(loginData: PoropsInter) {
  const {LoginBack} = loginData;
  const initgoogelbtn = ()=>{
      window.googleApi.accounts.id.initialize({
        client_id: clientId,
        callback: window.getGoogleInfo
      });
      window.googleApi.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      window.googleApi.accounts.id.prompt(); // also display the One Tap dialog
  }
  function loadGoogleScript() {
    const script = document.createElement("script");
    script.src = 'https://accounts.google.com/gsi/client';
    script.charset = "utf-8";
    script.async = true;
    script.onload = function () {
      window.googleApi = google;
      initgoogelbtn();
    };
    document.body.appendChild(script);
    window.IsLoadingGoogleyet = true;
  }

  window.getGoogleInfo = (resLogin:any)=>{
    if(resLogin?.credential){
      Common.Common.ShowLoading = true;
      LoginVerify.Instance.GoogleVerify(resLogin.credential,(logindt:any)=>{
        LoginBack?.(logindt);
      });
    }
  }

  useEffect(()=>{
    if(!window.IsLoadingGoogleyet){
      loadGoogleScript();
    }
    else if(window.googleApi){
      initgoogelbtn();
    }
  },[]);
  

  return (
    <div className={style.loginbutton} id="buttonDiv"></div>
  )
}
export default GoogleLogin;