import classnames from 'classnames/bind';
import style from './index.module.scss';
import Common from '../../Common/Common';
import CommonPop from '../CommonPop';

const className = classnames.bind(style);
const ServicePop = ()=>{
  const closeFunc = ()=>{
    Common.Common.ShowService = false;
  }
  return(
    <CommonPop closeWindown={closeFunc}>
    <div className={className('frame')}>
      <div className={className('whatapptitle')}>{'WhatsApp:18898324783'}</div>
      <div className={className('whatapptitle')}>{'WhatsApp QRcode:'}</div>
      <img className={className('qrcode')} src={'/images/common/whatsappqr.jpg'} alt="whatsapp"></img>
      <div className={className('email')}>{'Email:18898324783@163.com'}</div>
    </div>
    </CommonPop>
  )
}

export default ServicePop;