import { HttpNetPost } from "../Net/HttpNetMg";
import NetProtocol, { ShopCartProto, cartdata } from "../Net/NetProtocol";
import Common from "./Common";

export default class ShopCardData{
    private static instance: ShopCardData;
    public static get Instance():ShopCardData{
        if(!this.instance){
            this.instance = new ShopCardData();
        }
        return this.instance;
    }
    private isshow:boolean;
    constructor(){
        this.isshow = false;
    }

    public get Products():cartdata[]{
        if(Common.UserData){
            return Common.UserData.shopcart || [];
        }
        else{
            return []
        }
    }

    public get IsShow():boolean{
        return this.isshow;
    }

    public set IsShow(v:boolean){
        this.isshow = v && (Common.UserData !== null);
        Common.ShopCard = this;
    }

    private async save(shopcart:cartdata[]){
        if(Common.UserData){
            let cartdt:ShopCartProto = new ShopCartProto();
            cartdt.account = Common.UserData.account;
            cartdt.shopcart = shopcart;
            const resData:NetProtocol = await HttpNetPost("/shopcart", cartdt, true);
            if(resData.code === 0){
                Common.UserData = {shopcart: cartdt.shopcart};
                Common.ShopCard = this;
            }
        }
    }

    public AddData(data:cartdata){
        if(Common.UserData){
            let shopcart = Common.UserData.shopcart;
            if(!shopcart){
                shopcart = [];
            }
            for (let index = 0; index < shopcart.length; index++) {
                const element = shopcart[index];
                if(element.ProductId === data.ProductId && element.Names === data.Names){
                    element.Amount++;
                    Common.ShopCard = this;
                    this.save(shopcart);
                    return;
                }
            }
            shopcart.push(data);
            Common.ShopCard = this;
            this.save(shopcart);
        }
    }

    public RemoveData(data:cartdata){
        if(Common.UserData){
            let shopcart = Common.UserData.shopcart;
            if(!shopcart){
                shopcart = [];
            }
            for (let index = 0; index < shopcart.length; index++) {
                if(data === shopcart[index]){
                    shopcart.splice(index, 1);
                    this.save(shopcart);
                    return;
                }
            }
        }
    }

    public Clear(){
        this.save([]);
    }
}