const AreaCodeData = [
    "+1",//美国
    "+7",//俄罗斯
    "+31",//荷兰
    "+32",//比利时
    "+36",//匈牙利
    "+352",//卢森堡
    "+353",//爱尔兰
    "+39",//意大利
    "+420",//捷克
    "+44",//英国
    "+45",//丹麦
    "+49",//德国
    "+52",//墨西哥
    "+55",//巴西
    "+61",//澳大利亚
    "+64",//新西兰
    "+65",//新加坡
    "+81",//日本
    "+82",//韩国
    "+972",//以色列
    "+974",//卡塔尔
]

export default AreaCodeData;