import CommonPop from "../CommonPop";
import classnames from 'classnames/bind';
import style from './index.module.scss';
import Input from "../../components/Input";
import ComState from "../../Api/ComState";
import AreaCodeData from "../../Api/AreaCodeData";
import NetProtocol, { UserAddress } from "../../Net/NetProtocol";
import { HttpNetPost } from "../../Net/HttpNetMg";
import Common from "../../Common/Common";
import { useEffect } from "react";
const className = classnames.bind(style);

const initialState = {
    inputFrom: {        // 密码输入框内容
      fullname: '',
      areacode: '',
      phonenumber: '',
      country:'',
      address: '',
      postcodes: ''
    },
    invalidValid: {     // input输入错误提示
      fullname: {flag: false, errMsg: ''},
      areacode: {flag: false, errMsg: ''},
      phonenumber: {flag: false, errMsg: ''},
      country: {flag: false, errMsg: ''},
      address: {flag: false, errMsg: ''},
      postcodes: {flag: false, errMsg: ''}
    }
}

const Address = ()=>{
    const { state, updateState } = ComState(initialState);
    const {
        inputFrom,
        invalidValid
    } = state.mdata;
    // 更新input
    const updateInputFrom = (type: string, value: string) => {
      const temp:any = {}
      temp[type] = value;
      updateState({inputFrom: Object.assign({}, inputFrom, temp)});
    }

    const validEmptyInput = (inputdata: string)=>{
      if(!inputdata){
        return false;
      }
      return true;
    }

    useEffect(()=>{
      const userInfo = Common.UserData;
      if(userInfo){
        updateState({
          inputFrom:{
            fullname:userInfo.fullname,
            areacode:userInfo.areacode,
            phonenumber:userInfo.phonenum,
            country:userInfo.country,
            address:userInfo.address,
            postcodes:userInfo.postcodes
          }
        });
      }
      
    }, [])

    const validAllFromItem = ()=>{
      updateState({
        invalidValid: {
          fullname: {
            flag: !validEmptyInput(inputFrom.fullname),
            errMsg: "Content cannot be empty"
          },
          areacode: {
            flag: !validEmptyInput(inputFrom.areacode),
            errMsg: "Content cannot be empty"
          },
          phonenumber: {
            flag: !validEmptyInput(inputFrom.phonenumber),
            errMsg: "Content cannot be empty"
          },
          country: {
            flag: !validEmptyInput(inputFrom.country),
            errMsg: "Content cannot be empty"
          },
          address: {
            flag: !validEmptyInput(inputFrom.address),
            errMsg: "Content cannot be empty"
          }
        }
      })
    }

    // 表单验证
    const validAllFrom = () => {
      const validMap = [
        validEmptyInput(inputFrom.fullname),
        validEmptyInput(inputFrom.areacode),
        validEmptyInput(inputFrom.phonenumber),
        validEmptyInput(inputFrom.country),
        validEmptyInput(inputFrom.address),
      ]
      return validMap.every(item => item);
    }
    const onSubmitAddress = async ()=>{
      const userInfo = Common.UserData;
      if(!userInfo){
        Common.Common.ShowLogin = true;
        return;
      }
      if(!validAllFrom()) {
        validAllFromItem();
        return;
      }
      let useradr: UserAddress = new UserAddress();
      useradr.account = userInfo.account;
      useradr.fullname = inputFrom.fullname;
      useradr.areacode = inputFrom.areacode;
      useradr.phonenum = inputFrom.phonenumber;
      useradr.country = inputFrom.country;
      useradr.address = inputFrom.address;
      useradr.postcodes = inputFrom.postcodes;
      const resData:NetProtocol = await HttpNetPost("/setcontact", useradr, true);
      if(resData.code === 0){
        Common.Common.PopTips = "Successfully saved";
        userInfo.fullname = inputFrom.fullname;
        userInfo.areacode = inputFrom.areacode;
        userInfo.phonenum = inputFrom.phonenumber;
        userInfo.country = inputFrom.country;
        userInfo.address = inputFrom.address;
        userInfo.postcodes = inputFrom.postcodes;
      }
    }

    const close = ()=>{
      Common.Common.ShowAddress = false;
    }

    return(
        <CommonPop closeWindown={close} titlestr={'Address'}>
            <div className={className('main')}>
              <div className={className('input_wrap')}>
                <Input
                    required
                    mode="email"
                    label={"full name"}
                    labelStyle={{color: '#97A3B0'}}
                    type="text"
                    value={inputFrom.fullname}
                    changeInputValue={value => updateInputFrom('fullname', value)}
                    invalidFlag={invalidValid.fullname.flag}
                    invalidMsg={invalidValid.fullname.errMsg}
                    placeholder={"full name"}
                />
                
                <div className={className("phonenumber")}>
                    <div className={className("item1")}>
                    <Input
                      required
                      mode='selection'
                      label='areacode'
                      value={inputFrom.areacode}
                      changeInputValue={value => updateInputFrom('areacode', value)}
                      invalidFlag={invalidValid.areacode.flag}
                      invalidMsg={invalidValid.areacode.errMsg}
                      placeholder='+areacode'
                      inputBlockStyle={{width: '100%'}}
                      options={AreaCodeData}
                    />
                    </div>
                    <div className={className("item2")}>
                    <Input
                      required
                      mode='general'
                      label='phone number'
                      value={inputFrom.phonenumber}
                      changeInputValue={value => updateInputFrom('phonenumber', value)}
                      invalidFlag={invalidValid.phonenumber.flag}
                      invalidMsg={invalidValid.phonenumber.errMsg}
                      placeholder='phone number'
                      inputBlockStyle={{width: '100%'}}
                    />
                    </div>
                </div>
                <Input
                    required
                    mode="email"
                    label={"country"}
                    labelStyle={{color: '#97A3B0'}}
                    type="text"
                    value={inputFrom.country}
                    changeInputValue={value => updateInputFrom('country', value)}
                    invalidFlag={invalidValid.country.flag}
                    invalidMsg={invalidValid.country.errMsg}
                    placeholder={"country"}
                />
                <Input
                    required
                    mode="email"
                    label={"address"}
                    labelStyle={{color: '#97A3B0'}}
                    type="text"
                    value={inputFrom.address}
                    changeInputValue={value => updateInputFrom('address', value)}
                    invalidFlag={invalidValid.address.flag}
                    invalidMsg={invalidValid.address.errMsg}
                    placeholder={"address"}
                />
                <Input
                    mode="email"
                    label={"postcodes"}
                    labelStyle={{color: '#97A3B0'}}
                    type="text"
                    value={inputFrom.postcodes}
                    changeInputValue={value => updateInputFrom('postcodes', value)}
                    invalidFlag={invalidValid.postcodes.flag}
                    invalidMsg={invalidValid.postcodes.errMsg}
                    placeholder={"postcodes"}
                />
              </div>
              <button className={className('submit_btn')} onClick={onSubmitAddress}>Submit</button>
            </div>
        </CommonPop>
    )
}

export default Address;