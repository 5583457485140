

export class HttpCommon 
{
    public static ApiBaseUrl = process.env.PUBLIC_API_BASE;//window.location.protocol
    public static Token:string = "";
    public static getString(url: string, callback: (statusCode: number, respText: string) => any){
        let xhr = new XMLHttpRequest();
        xhr.open("GET", this.ApiBaseUrl+url, true);
        HttpCommon.SetRequestHeader(xhr);
        xhr.onreadystatechange = function () {
            switch(xhr.readyState){
                case 4:
                    callback?.(xhr.status, xhr.responseText);
                    break;
            }
        };
        xhr.onerror = function (err) {
            callback?.(-1, "Network error");
        };
        xhr.send();
    }

    public static postString(url: string, data: object | string, callback: (statusCode: number, respText: string) => any){
        let xhr = new XMLHttpRequest();
        xhr.open("POST", this.ApiBaseUrl+url, true);
        HttpCommon.SetRequestHeader(xhr);
        xhr.onreadystatechange = function () {
            switch(xhr.readyState){
                case 4:
                    callback?.(xhr.status, xhr.responseText);
                    break;
            }
        };
        xhr.onerror = function (err) {
            callback?.(-1, "Network error");
        };
        xhr.send(data?JSON.stringify(data):null);
    }

    public static SetRequestHeader(xhr: XMLHttpRequest){
        xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        if(this.Token && this.Token !== ""){
            xhr.setRequestHeader("x-tokens", this.Token);
        }
    }
}