import classnames from 'classnames/bind';
import style from './index.module.scss';
import CommonPop from '../CommonPop';
import Common from '../../Common/Common';
import { useEffect } from 'react';
import Modifypsd from './Modifypsd';
import ShowAddress from './ShowAddress';
const className = classnames.bind(style);

const Account = ()=>{
    const close = () => {
        Common.Common.ShowAccount = false;
    }

    const onShoppingCart = ()=>{
        Common.ShopCard.IsShow = true;
        close();
    }

    const onOrderRecord = ()=>{
        Common.Orders.IsShow = true;
        close();
    }

    const onPrivacyPolicy = ()=>{
        close();
        Common.Common.ShowLoading = true;
        Common.Common.ShowIframe = true;
    }

    const onQuitLogin = ()=>{
        close();
        Common.UserData = null;
    }

    useEffect(()=>{
        if(!Common.UserData){
            close();
        }
    },[Common.UserData])
    
    return(
        <CommonPop closeWindown={close} titlestr={'Account'}>
            <div className={className('main')}>
                <div className={className('account')}>
                    {"Account: " + Common.UserData.account}
                </div>
                <Modifypsd/>
                <div className={className('interval')}/>
                <ShowAddress/>
                <div className={className('interval')}/>
                <div className={className('btnstype')} onClick={onShoppingCart}>
                    <span>{"Open Shopping Cart"}</span>
                </div>
                <div className={className('interval')}/>
                <div className={className('btnstype')} onClick={onOrderRecord}>
                    <span>{"Open Order Record"}</span>
                </div>
                <div className={className('interval')}/>
                <div className={className('btnstype')} onClick={onPrivacyPolicy}>
                    <span>{"Privacy Policy"}</span>
                </div>
                <div className={className('interval')}/>
                <div className={className('btnstype')} onClick={onQuitLogin}>
                    <span>{"Log Out"}</span>
                </div>
            </div>
        </CommonPop>
    )
}

export default Account;