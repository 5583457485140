import classnames from 'classnames/bind';
import style from './index.module.scss';
import SignNameInput from '../SignNameInput';
import useSignNameInput from '../hooks/useSignNameInput';
import Common from '../../../Common/Common';
import InputVerify, { VerifyData } from '../../../components/InputVerify';
import { RegisterProto } from '../../../Net/NetProtocol';
import UserInfoData from '../../../Common/UserInfoData';
const className = classnames.bind(style);

const RegisterLayout = ()=>{
    const { nameInput, invalidName, onUpdateNameInput, isValidNameInput, isValidEmailInput} = useSignNameInput();
    const onChekRegister = ()=>{
        if(isValidNameInput()){
            let authcode = VerifyData.GetAuthCode();
            if(authcode){
                const {email,password} = nameInput;
                let ndata:RegisterProto = new RegisterProto();
                ndata.account = email;
                ndata.password = password;
                ndata.verifycode = authcode;
                UserInfoData.Instance.UserReGister(ndata);
            }
        }
    }
    return(
        <>
        <div className={className('register_wrap')}>
            <SignNameInput nameInput={nameInput} onUpdateNameInput={onUpdateNameInput} invalidName={invalidName}/>
            <InputVerify verifytype={0} nameInput={nameInput} isValidEmailInput={isValidEmailInput}/>
            <div className={className('btn_start')} style={{filter: `grayscale(${Common.Common.ShowLoading ? 1 : 0})`}} onClick={onChekRegister}>
              <span>{"Log in to your account"}</span>
            </div>
        </div>
        </>
    )
}

export default RegisterLayout;