import { useCallback } from "react";
import Input from "../Input";
import style from './index.module.scss';
import classNames from "classnames/bind";
import ComState from "../../Api/ComState";
import { validPostcode } from "../../Api/validation";
import Common from "../../Common/Common";
import { VerifyCode } from "../../Net/NetProtocol";
const className = classNames.bind(style);

interface PoropsInter{
    verifytype:number,
    nameInput:any,
    isValidEmailInput:any
}

const initialState = {
    authcode: '',
    invalidRegisterValid: {
      authcode: {flag: false, errMsg: ''},
    }
};

export class VerifyData
{
    public static GetAuthCode:()=>string;
}

const InputVerify = (useNameInput:PoropsInter)=>{
    const { verifytype, nameInput, isValidEmailInput } = useNameInput;
    const codeData = Common.Code;
    const { state, updateState } = ComState(initialState);
    const { authcode, invalidRegisterValid } = state.mdata;
    // 验证码验证失败文案
    const onAuthcodeVaildText = useCallback((): string => {
      if (!authcode) {
        return "enter verification code";
      } else if (!validPostcode(authcode)) {
        return "Verification code error";
      } else {
        return ''
      }
    }, [authcode])

    const validContent = useCallback((type?: string): void => {
        const invalidData:any = {
          authcode: {
            flag: !validPostcode(authcode),
            errMsg: onAuthcodeVaildText()
          }
        }
        const obj: any = {};
        if(type) {
          obj[type] = invalidData[type]
        };
        updateState({
          invalidRegisterValid: invalidData
        })
    }, [authcode])

    VerifyData.GetAuthCode = ()=>{
        if(!validPostcode(authcode)) {
            validContent('authcode');
            return;
          }
        return authcode;
    }

    const sendAuthCode = ()=>{
        if(codeData.DownTimeNum > 0){
            return;
        }
        if(isValidEmailInput()){
            const {email} = nameInput;
            let ndata:VerifyCode = new VerifyCode();
            ndata.account = email;
            ndata.actype = verifytype;
            codeData.SendEmailCode(ndata);
        }
    }

    return(
        <div className={className('verifyinput_wrap')}>
        <Input
            required
            label={"Verify Code"}
            value={ authcode }
            changeInputValue={(value: any) => updateState({authcode:value})}
            invalidFlag={invalidRegisterValid.authcode.flag}
            invalidMsg={invalidRegisterValid.authcode.errMsg}
            inputStyle={{flex: 1, backgroundColor: "#111217"}}
            placeholder={"verify code"}
            customRightBtn={
              <div className={className("right_send_btn_wrap")} onClick={sendAuthCode}>
                {codeData.DownTimeNum > 0 ? "Sent" + '(' + codeData.DownTimeNum + ')s' : "Send E-mail"}
              </div>
            }/>
        </div>
    )
}

export default InputVerify;