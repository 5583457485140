import CryptoJS from "crypto-js";
const openfb = false;
declare const window:any;

export function fbInit(email:string){
    if(!openfb) return;
    if(window.fbq == null || window.fbq == undefined){
        console.log('=====>>>fbq is undefined');
    }
    else
    {
        const hasEmail:string = CryptoJS.SHA256(email).toString();
        window.fbq('init', "1169076617637272", {em:hasEmail});
    }
}

export function pushFbEvent(eventname:string, eventobj:any){
    if(!openfb) return;
    if(window.fbq == null || window.fbq == undefined){
        console.log('=====>>>fbq is undefined');
    }
    else
    {
        window.fbq('track', eventname, eventobj);
        console.log("===>>>track: ", eventname, eventobj);
    }
}

export function pushFbCustom(eventname:string, eventobj:any){
    if(!openfb) return;
    if(window.fbq == null || window.fbq == undefined){
        console.log('=====>>>fbq is undefined');
    }
    else
    {
        window.fbq('trackCustom', eventname, eventobj);
        console.log("===>>>track: ", eventname, eventobj);
    }
}