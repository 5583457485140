import Common from "../../../Common/Common";
import CommonPop from "../../CommonPop";
import classnames from 'classnames/bind';
import style from './index.module.scss';
import { useMemo } from "react";
import { PayPalCaptureProto, cartdata } from "../../../Net/NetProtocol";
import { PayPalButtons, PayPalScriptProvider, ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import { PaypalCom } from "../../../Api/PaypalCommon";
const className = classnames.bind(style);

const initialOptions: ReactPayPalScriptOptions = {
    clientId:process.env.PAYPAL_CLIENT_ID||""
};

const RePayOrder = ()=>{

    const closeFunc = ()=>{
        Common.Orders.SelectPay = null;
    }

    const prod = useMemo(()=>{
        return Common.Orders.SelectPay;
    },[]);

    const GetImgUrlById = (pid:string, imgid:number):string=>{
        return "/product/product/" + pid + "/" + imgid + ".jpg";
    }

    const createOrder = async ()=> {
        return prod.orderid;
    }
    
    const onApprove = async (data:any)=> {
        let ndata: PayPalCaptureProto = new PayPalCaptureProto();
        ndata.account = Common.UserData.account;
        ndata.orderid = data.orderID;
        const {orderid, status} = await PaypalCom.MyCaptureOrder(ndata);
        if(orderid){
            if(orderid === prod.orderid){
                prod.state = PaypalCom.GetStatus(status);
            }
            Common.Common.PopTips = "Payment successful";
            Common.Orders.SelectPay = null;
        }
    }

    return(
        <CommonPop closeWindown={closeFunc} titlestr={"Order Record"}>
            <div className={className('contentbox')}>
                <section className={className('scrollable')}>
                    <div className={className('item')}>
                    {
                        prod && prod.shopcart.map((nitem:cartdata,index:number)=>{
                            return(
                            <div className={className('itembox')} key={"p"+index}>
                                <div className={className('picture')}>
                                    <img src={GetImgUrlById(nitem.ProductId, nitem.ImgeId)} alt={nitem.ProductId}/>
                                </div>
                                <div className={className('details')}>
                                    <span>{nitem.ProductId}&nbsp;</span>
                                    <span>{nitem.Names}&nbsp;</span>
                                    <span>{'x'+nitem.Amount}&nbsp;</span>
                                    <span>{'$'+(Math.round(nitem.Amount*nitem.Price*100)/100).toString()}&nbsp;</span>
                                </div>
                            </div>
                            )
                        })
                    }
                    </div>
                </section>
                <div className={className('paybutton')}>
                <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        style={{ layout: "horizontal" }}
                    />
                </PayPalScriptProvider>
                </div>
            </div>
        </CommonPop>
    )
}

export default RePayOrder;