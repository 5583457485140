import classnames from 'classnames/bind';
import style from './index.module.scss';
import NoData from '../../components/NoData';
import CommonPop from '../CommonPop';
import Common from '../../Common/Common';
import { useEffect, useState } from 'react';
import { product } from '../../Net/NetProtocol';
import ShowItems from './ShowItems';
import ShowImge from '../ShowImge';
const className = classnames.bind(style);

const OrderRecord = ()=>{
    const [ImageUrl, SetImageUrl] = useState("");

    const ShowPidImage = (imgurl: string)=>{
        SetImageUrl(imgurl);
    }
    
    const closeFunc = ()=>{
        Common.Orders.IsShow = false;
    }

    const scrollfunc = ()=>{
        const container = document.getElementById('myScrollable');
        if(container){
            container.addEventListener('scroll', function() {
                const scrollTop = container.scrollTop;
                const scrollHeight = container.scrollHeight;
                const clientHeight = container.clientHeight;
                // 当滚动到底部时，加载数据
                if (scrollTop + clientHeight >= scrollHeight - 5) { // 减5是为了避免误差
                  console.log('Reached bottom, loading data...');
                  Common.Orders.GetProduct();
                }
            });
        }
    }

    useEffect(()=>{
        Common.Orders.GetProduct();
        scrollfunc();
    },[]);

    return(
        <>
        <CommonPop closeWindown={closeFunc} titlestr={"Order Record"}>
            <div className={className('contentbox')}>
                <section className={className('scrollable')} id="myScrollable">
                    {
                        Common.Orders.Products.length > 0&&Common.Orders.Products.map((item:product,index:number)=>{
                            return(
                                <ShowItems item={item} showPidImage={ShowPidImage} key = {"o"+index} />
                            )
                        })
                    }
                    {
                        (Common.Orders.Products.length === 0 && !Common.Orders.IsLoading)&&<NoData/>
                    }
                </section>
            </div>
            
        </CommonPop>
            {
               ImageUrl !== "" &&<ShowImge ImageUrl={ImageUrl} CloseImg={()=>{SetImageUrl("")}}></ShowImge>
            }
        </>
    )
}

export default OrderRecord;