import Common from "../Common/Common";
import { HttpNetPost } from "../Net/HttpNetMg";
import NetProtocol, { CPaypalTokenProto, PayPalCancelProto, PayPalCaptureProto, PayPalPayProto, SPaypalTokenProto } from "../Net/NetProtocol";

export namespace PaypalCom{
  export enum orderstatus{
    CREATED = 0,//订单是使用指定的上下文创建的。
    SAVED,//订单已保存并保留。订单状态将继续进行，直到对订单中的所有采购单位进行捕获。final_capture = true
    APPROVED,//客户通过PayPal钱包或其他形式的访客或无品牌付款批准了付款。例如，银行卡、银行帐户等。
    VOIDED,//订单中的所有采购单位均作废。
    COMPLETED,//订单完成
    PAYER_ACTION_REQUIRED//该订单需要付款人执行操作（e.g. 3DS身份验证）。
  }

  export function GetStatus(status: string): number{
    let nstatus = 0;
    switch(status){
      case "CREATED":
          nstatus = orderstatus["CREATED"];
        break;
      case "SAVED":
          nstatus = orderstatus["SAVED"];
        break;
      case "APPROVED":
          nstatus = orderstatus["APPROVED"];
        break;
      case "VOIDED":
          nstatus = orderstatus["VOIDED"];
        break;
      case "PAYER_ACTION_REQUIRED":
          nstatus = orderstatus["PAYER_ACTION_REQUIRED"];
        break;
    }
    return nstatus;
  }

  export async function MyCreateOrder(ndata: PayPalPayProto){
    const resData:NetProtocol = await HttpNetPost("/paypalpay", ndata, false);
    if(resData.code === 0){
      let userData = Common.UserData;
      if(userData){
        if(!userData.products){
          userData.products = [resData.data.timed];
        }
        else{
          userData.products.push(resData.data.timed);
        }
        Common.Orders.ClearProducts();
      }
      return resData.data.orderid;
    }
    else{
      Common.Common.PopTips = resData.msg;
      return "";
    }
  }
  
  export async function MyCaptureOrder(ndata: PayPalCaptureProto) {
    const resData:NetProtocol = await HttpNetPost("/paypalcapture", ndata, false);
    if(resData.code === 0){
      return resData.data;
    }
    else{
      Common.Common.PopTips = resData.msg;
      return {}
    }
  }

  export async function CanCelOrder(ndata: PayPalCancelProto) {
    const resData:NetProtocol = await HttpNetPost("/paypalcancel", ndata, false);
    if(resData.code !== 0){
      Common.Common.PopTips = resData.msg;
    }
  }
  
  const PlayPalToken = {
    paypaltoken:"",
    expirestime:0
  }
  
  export async function GetPaypalToken(ndata:CPaypalTokenProto) {
    if(PlayPalToken.paypaltoken !== "" && PlayPalToken.expirestime > Date.now()){
      return PlayPalToken.paypaltoken;
    }
    const resData:NetProtocol = await HttpNetPost("/paypalcapture", ndata, false);
    if(resData.code === 0){
      let tokendt: SPaypalTokenProto = resData.data;
      PlayPalToken.paypaltoken = tokendt.paypaltoken;
      PlayPalToken.expirestime = tokendt.expires_in + Date.now();
      return tokendt.paypaltoken;
    }
    else{
      Common.Common.PopTips = resData.msg;
    }
  }
}