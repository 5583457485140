import classnames from 'classnames/bind'
import style from './index.module.scss'

const ImgExclamate = '/images/components/inputError/exclamate.png'
const className = classnames.bind(style)

interface InputErrMsgProps {
  flag: boolean,
  msg: string | undefined,
  errStyle?: any
}

const InputErrMsg = ({
  flag,
  msg,
  errStyle = {}
}: InputErrMsgProps) => {
  return (
    <>
      <p className={className('error_text_wrap', `${flag ? 'error_msg_open' : 'error_msg_clse'}`)} style={errStyle}>
        {flag && msg && (
          <>
            <img src={ImgExclamate} alt='Eye' width={10} height={10} />
            <span>{msg}</span>
          </>
        )}
      </p>
    </>
  )
}

export default InputErrMsg
