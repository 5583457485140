import { HttpNetPost } from "../Net/HttpNetMg";
import NetProtocol, { CDelOrthersProto, CGetOrthersProto, SDelOrthersProto, SGetOrthersProto, product } from "../Net/NetProtocol";
import Common from "./Common";

export default class OrderRecordData{
    private static instance: OrderRecordData;
    public static get Instance():OrderRecordData{
        if(!this.instance){
            this.instance = new OrderRecordData();
        }
        return this.instance;
    }
    private isshow:boolean;
    private products:product[];
    private isLoading:boolean;
    private selectpd:any;
    constructor(){
        this.isshow = false;
        this.isLoading = false;
        this.products = [];
        this.selectpd = null;
    }
    
    public get IsShow():boolean{
        return this.isshow;
    }
    public set IsShow(v:boolean){
        this.isshow = v && (Common.UserData !== null);
        Common.Orders = this;
    }

    public get IsLoading():boolean{
        return this.isLoading;
    }

    public get Products():product[]{
        if(Common.UserData){
            return this.products;
        }
        else{
            return []
        }
    }

    public ClearProducts()
    {
        this.products = [];
    }

    public Refresh(){
        Common.Orders = this;
    }

    public get SelectPay(): product{
        return this.selectpd;
    }

    public set SelectPay(v:any){
        this.selectpd = v;
        Common.Orders = this;
    }

    public async GetProduct(){
        if(Common.UserData && !this.isLoading){
            let pids = Common.UserData.products;
            if(pids && pids.length > this.products.length){
                let getorders: CGetOrthersProto = new CGetOrthersProto();
                getorders.account = Common.UserData.account;
                getorders.timeds = [];
                let nfrom = pids.length - this.products.length - 1;
                let nto = nfrom > 4?nfrom-4:0;
                for (let index = nfrom; index >= nto; index--) {
                    getorders.timeds.push(pids[index]);
                }
                this.isLoading = true;
                const resData:NetProtocol = await HttpNetPost("/getorders", getorders, false);
                this.isLoading = false;
                if(resData.code === 0){
                    let norders: SGetOrthersProto = resData.data;
                    if(norders.orthers){
                        this.products.push(...norders.orthers);
                        Common.Orders = this;
                    }
                }
                else{
                    Common.Common.PopTips = resData.msg;
                }
            }
        }
    }
    
    public async DelProduct(timed: number){
        if(Common.UserData && !this.isLoading){
            let delorders: CDelOrthersProto = new CDelOrthersProto();
            delorders.account = Common.UserData.account;
            delorders.timeds = [timed];
            const resData:NetProtocol = await HttpNetPost("/delorders", delorders, false);
            if(resData.code === 0){
                let norders: SDelOrthersProto = resData.data;
                Common.UserData.products = norders.timeds;
                let nindex = this.products.findIndex(item=>item.timed === timed);
                this.products.splice(nindex, 1);
                Common.Orders = this;
            }
            else{
                Common.Common.PopTips = resData.msg;
            }
        }
    }
}