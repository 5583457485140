import classnames from 'classnames/bind';
import style from './index.module.scss';
import { PayPalCaptureProto, cartdata, product } from '../../Net/NetProtocol';
import Common from '../../Common/Common';
import { PaypalCom } from '../../Api/PaypalCommon';

const refreshimg = "/images/common/refresh.png";
const delectimg = '/images/components/inputHintPop/register_error.png';
const className = classnames.bind(style);

interface PropsTarget {
    item:product,
    showPidImage:(imgurl: string)=>void
}

const orderstatus = [
    "Unpaid",
    "Unpaid",
    "Approved",
    "Voided",
    "Paid",
    "Unauthorized",
    "Shipped"
]

const ShowItems = (props: PropsTarget)=>{
    const { item,showPidImage } = props;
    const NRouter = Common.Common.NRouter;
    const GetImgUrlById = (pid:string, imgid:number):string=>{
        return "/product/product/" + pid + "/" + imgid + ".jpg";
    }
    const ShowImage = (pid:string, imgid:number)=>{
        showPidImage(GetImgUrlById(pid, imgid));
    }
    const datetimetostr = (timed:number)=>{
        let timestr = "";
        let timedts = new Date(timed);
        timestr = timedts.toLocaleDateString() + " " + timedts.toLocaleTimeString();
        return timestr;
    }
    const getstatus = (status:number)=>{
        return orderstatus[status];
    }
    const showProduct = (pid:string)=>{
        Common.Orders.IsShow = false;
        NRouter?.('/Product/'+pid);
    }
    const RefreshOrder = async ()=> {
        if(Common.UserData){
            let captorder = new PayPalCaptureProto();
            captorder.account = Common.UserData.account;
            captorder.orderid = item.orderid;
            const {orderid, status} = await PaypalCom.MyCaptureOrder(captorder);
            if(orderid === item.orderid){
                const nstate:number = PaypalCom.GetStatus(status);
                if(item.state !== nstate){
                    item.state = nstate;
                    Common.Orders.Refresh();
                }
                if(status <= 1){
                    Common.Orders.SelectPay = item;
                }
            }
        }
    }

    const DelectOrder = ()=>{
        Common.Orders.DelProduct(item.timed);
    }

    const subname = (namestr: string):string=>{
        if(namestr.length > 10){
            return namestr.substring(0, 10)+"..."
        }
        return namestr;
    }

    return(
        <div className={className('datalist')}>
            <div className={className('orderdetails')}>
                <div className={className('left')}>
                    <div className={className('orderid')} >
                        {"OrderId: " + item.orderid}
                    </div>
                    <div className={className('paymoney')} >
                        {"Payment: $" + item.price}
                    </div>
                </div>
                <div className={className('right')}>
                    <div className={className('righttop')}>
                        {
                            (item.state === 0 || item.state === 5 || item.timed + 2592000000 < Date.now()) &&
                            <div className={className('imgdiv')} onClick={DelectOrder}>
                                <img src={delectimg} />
                            </div>
                        }
                        {
                            (item.state <= 2 && item.timed + 259200000 > Date.now()) &&
                            <div className={className('imgdiv')} onClick={RefreshOrder}>
                                <img src={refreshimg} />
                            </div>
                        }
                        <div className={className('status')} >
                            {"State:" + getstatus(item.state)}
                        </div>
                    </div>
                    <div className={className('tracking')} >{item.state === 6? "Track:"+item.trackingnum:""}</div>
                    <div className={className('datetimetxt')}>
                        {datetimetostr(item.timed)}
                    </div>
                </div>
            </div>
            <div className={className('item')}>
            {
                item.shopcart.map((nitem:cartdata,index:number)=>{
                    return(
                    <div className={className('itembox')} key={"p"+index}>
                        <div className={className('picture')}>
                            <img src={GetImgUrlById(nitem.ProductId, nitem.ImgeId)} onClick={()=>ShowImage(nitem.ProductId, nitem.ImgeId)} alt={nitem.ProductId}/>
                        </div>
                        <div className={className('details')} >
                            <div className={className('itemdiv')} >
                                <div className={className('title')}>{"No.: "}<span>{subname(nitem.ProductId)}</span></div>
                                <div className={className('title')}>{"types: "}<span>{subname(nitem.Names)}</span></div>
                            </div>
                            <div className={className('itemdiv')} >
                                <div className={className('title')}>{"amount: "}<span>{nitem.Amount}</span></div>
                                <div className={className('title')}>{"price: "}<span>{"$"+(Math.round(nitem.Amount*nitem.Price*100)/100).toString()}</span></div>
                            </div>
                        </div>
                        <div className={className('gobutton')} >
                            <button onClick={()=>{showProduct(nitem.ProductId)}}>details</button>
                        </div>
                    </div>
                    )
                })
            }
            </div>
        </div>
    )
}

export default ShowItems;