import { HttpCommon } from "../Net/HttpCommon";
import { NetByte } from "../Net/NetByte";
import NetWebSocket from "../Net/NetWebSocket";
import { CWsChat, CWsGetChat, CWsLogin, CWsRead, ChatData, Protocol, SCWsGetChat, SWsChat, SWsLogin, SWsLoginErro, SWsRead } from "../Net/WsProtocol";
import Common from "./Common";

export class UserChatDt{
    public account:string;
    public chatdatas: ChatData[];
    public isread1:number = 0; // 官方已读
    public isread2:number = 0; // 用户已读
    public ntimes:number = 0;
    constructor(account:string){
        this.account = account;
        const chatstr = localStorage?.getItem(account+"_chat");
        if(chatstr){
            this.chatdatas = JSON.parse(chatstr);
            this.ntimes = this.LastChatTime;
        }
        else{
            this.chatdatas = [];
        }
    }

    public AddChat(cahtdts: ChatData[]){
        this.chatdatas.push(...cahtdts);
        localStorage?.setItem(this.account+"_chat", JSON.stringify(this.chatdatas));
    }

    public get LastChatTime():number{
        let index = this.chatdatas.length - 1;
        if(index >= 0){
            return this.chatdatas[index].times;
        }
        return 0;
    }
}

export default class MgChatData{
    private static instance:MgChatData;
    public static get Instance() : MgChatData{
        if(!this.instance){
            this.instance = new MgChatData();
        }
        return this.instance;
    }

    private chatdatas: UserChatDt[];
    private selectchat: UserChatDt|null|undefined;
    private webSocket: NetWebSocket;
    constructor(){
        this.chatdatas = [];
        this.selectchat = null;
        this.webSocket = new NetWebSocket();
        this.webSocket.onclose = this.NetClose.bind(this);
        this.webSocket.onerror = this.NetError.bind(this);
        this.webSocket.onopen = this.NetOpen.bind(this);
        this.webSocket.AddNet(Protocol.slogin, this.LoginBack.bind(this));
        this.webSocket.AddNet(Protocol.sloginerro, this.LoginErroBack.bind(this));
        this.webSocket.AddNet(Protocol.schat, this.ChatBack.bind(this));
        this.webSocket.AddNet(Protocol.sgetchat, this.GetChatBack.bind(this));
        this.webSocket.AddNet(Protocol.sread, this.ReadChatBack.bind(this));
    }

    public GetChatDatas(account:string): UserChatDt{
        let chatdt:any;
        chatdt = this.chatdatas.find(chatdt=>chatdt.account === account);
        if(!chatdt){
            chatdt = new UserChatDt(account);
            this.chatdatas.push(chatdt);
        }
        return chatdt;
    }

    public get HasRead():boolean{
        let hasmsg = false;
        const userinfo = Common.UserData;
        if(userinfo){
            for (let index = 0; index < this.chatdatas.length; index++) {
                const element = this.chatdatas[index];
                if(userinfo.actype === 100){
                    if(element.isread1 < element.ntimes){
                        hasmsg = true;
                        break;
                    }
                }
                else{
                    if(element.isread2 < element.ntimes){
                        hasmsg = true;
                        break;
                    }
                }
            }
        }
        return hasmsg;
    }

    public get ChatDatas(): UserChatDt[]{
        return this.chatdatas;
    }

    public get SelectChat():UserChatDt|null|undefined{
        return this.selectchat;
    }
    public set SelectChat(v:UserChatDt|null|undefined){
        this.selectchat = v;
        Common.Chat = this;
    }

    NetClose(){

    }

    NetError(){
        this.chatdatas = [];
    }

    NewProtocol(prot: Protocol): NetByte{
        const bytes = new NetByte();
        bytes.writeUint16(0);
        bytes.writeUint8(prot);
        return bytes;
    }

    NetOpen(){
        const userinfo = Common.UserData;
        if(userinfo){
            let msg:CWsLogin = new CWsLogin();
            msg.account = userinfo.account;
            msg.token = HttpCommon.Token;
            this.webSocket.SendData(CWsLogin.LWriteFrom(msg, Protocol.clogin));
        }
    }

    public Connect(){
        if(process.env.CHAT_SERVER){
            this.webSocket.OpenSocket(process.env.CHAT_SERVER);
        }
    }

    public SendChat(chats:string){
        if(this.selectchat){
            let msg:CWsChat = new CWsChat();
            msg.account = this.selectchat.account;
            msg.chat = chats;
            this.webSocket.SendData(CWsChat.LWriteFrom(msg, Protocol.cchat));
        }
    }

    public SendRead(){
        if(this.selectchat){
            let msg:CWsRead = new CWsRead();
            msg.account = this.selectchat.account;
            this.webSocket.SendData(CWsRead.LWriteFrom(msg, Protocol.cread));
        }
    }

    public SendGetChat(account:string, times: number){
        let msg:CWsGetChat = new CWsGetChat();
        msg.account = account;
        msg.times = times;
        this.webSocket.SendData(CWsGetChat.LWriteFrom(msg, Protocol.cgetchat));
    }

    LoginBack(bytes: NetByte){
        let msg: SWsLogin = SWsLogin.ReadFrom(bytes);
        this.chatdatas = [];
        for (let index = 0; index < msg.chattimes.length; index++) {
            const element = msg.chattimes[index];
            let chatdts = new UserChatDt(element.account);
            chatdts.isread1 = element.isread1;
            chatdts.isread2 = element.isread2;
            chatdts.ntimes = element.ctime;
            let chattime = chatdts.LastChatTime;
            if(chattime < element.ctime){
                this.SendGetChat(element.account, chattime);
            }
            this.chatdatas.push(chatdts);
        }
        Common.Chat = this;
    }

    LoginErroBack(bytes: NetByte){
        let msg: SWsLoginErro = SWsLoginErro.ReadFrom(bytes);
        if(msg.errocode === 1){
            Common.UserData = null;
            Common.Common.ShowLogin = true;
        }
    }

    ChatBack(bytes: NetByte){
        const userinfo = Common.UserData;
        let msg: SWsChat = SWsChat.ReadFrom(bytes);
        let chatdts = this.chatdatas.find(chatdt=>chatdt.account === msg.account);
        if(chatdts === null||chatdts === undefined){
            chatdts = new UserChatDt(msg.account);
            this.chatdatas.push(chatdts);
        }
        chatdts.AddChat([msg.chat]);
        chatdts.ntimes = msg.chat.times;
        if(msg.chat.official === 1){
            if(userinfo.actype === 100)
            {
                chatdts.isread1 = msg.chat.times;
            }
            else{
                this.SendRead();
            }
        }
        else{
            if(userinfo.actype === 100)
            {
                this.SendRead();
            }
            else{
                chatdts.isread2 = msg.chat.times;
            }
        }
        Common.Chat = this;
    }

    GetChatBack(bytes: NetByte){
        let msg: SCWsGetChat = SCWsGetChat.ReadFrom(bytes);
        let chatdts = this.chatdatas.find(chatdt=>chatdt.account === msg.account);
        if(chatdts === null||chatdts === undefined){
            chatdts = new UserChatDt(msg.account);
            this.chatdatas.push(chatdts);
        }
        chatdts.AddChat(msg.records);
        Common.Chat = this;
    }

    ReadChatBack(bytes: NetByte){
        let msg: SWsRead = SWsRead.ReadFrom(bytes);
        let chatdts = this.chatdatas.find(chatdt=>chatdt.account === msg.account);
        if(chatdts){
            if(msg.official){
                chatdts.isread1 = chatdts.ntimes;
            }
            else{
                chatdts.isread2 = chatdts.ntimes;
            }
            Common.Chat = this;
        }
    }

    public Quit(){
        this.chatdatas = [];
        this.webSocket.CloseSocket();
    }
}