import { fbInit, pushFbCustom } from "../Api/FbEven";
import { HttpCommon } from "../Net/HttpCommon";
import { HttpNetPost } from "../Net/HttpNetMg";
import NetProtocol, { ChangePsdProto, LoginByTonken, LoginProto, ReSetPsdProto, RefreshToken, RegisterProto, cartdata } from "../Net/NetProtocol";
import Common from "./Common";
import { MD5 } from "crypto-js";

export default class UserInfoData{
    private static instance:UserInfoData;
    public static get Instance(): UserInfoData{
        if(!this.instance){
            this.instance = new UserInfoData();
        }
        return this.instance;
    }
    
    public account:string="";
    public password:string="";
    public actype:number=0;
    public fullname:string="";
    public areacode:string="";
    public phonenum:string="";
    public country:string="";
    public address:string="";
    public postcodes:string="";
    public products:number[]=[];
    public shopcart:cartdata[]=[];

    public InitData(data: any, classTb: any){
        let key: keyof any;
        for (key in data) {
            classTb[key] = data[key];
        }
    }

    public async UserReGister(ndata:RegisterProto){
        let password = ndata.password;
        ndata.password = MD5(password).toString();
        const resData:NetProtocol = await HttpNetPost("/register", ndata, true);
        if(resData.code === 0){
            this.LoginSuccess(resData.data, password, resData.token);
            pushFbCustom("CompleteRegistration", {registerty:0});
        }
    }

    public async UserLogin(ndata:LoginProto){
        let password = ndata.password;
        ndata.password = MD5(password).toString();
        const resData:NetProtocol = await HttpNetPost("/login", ndata, true);
        if(resData.code === 0){
            this.LoginSuccess(resData.data, password, resData.token, ndata.loginty);
        }
    }

    private LoginSuccess(data:any, password:string, token:string, loginty:number=0){
        HttpCommon.Token = token;
        Common.UserData = data;
        Common.Common.ShowLogin = false;
        Common.Common.MessageTarget = {
            type: 'welcome',
            title: 'Welcome',
            config: { kind: 11, messageType: 'welcome', privateQuery: { account:this.account } }
        }
        if(loginty === 0){
            localStorage?.setItem('LoginAccount', JSON.stringify({account:this.account, password, token, loginty}));
        }
        this.RefreshtokenTime();
        Common.Chat.Connect();
        fbInit(this.account);
        pushFbCustom("login", {loginty});
    }

    public async UserReSetPwd(ndata:ReSetPsdProto){
        let password = ndata.password;
        ndata.password = MD5(password).toString();
        const resData:NetProtocol = await HttpNetPost("/resetpsd", ndata, true);
        if(resData.code === 0){
            Common.Common.PopTips = "Password reset successful";
            localStorage.removeItem('LoginAccount');
        }
    }

    public async UserChangePwd(ndata:ChangePsdProto){
        let password = ndata.newpassword;
        ndata.newpassword = MD5(password).toString();
        ndata.oldpassword = MD5(ndata.oldpassword).toString();
        const resData:NetProtocol = await HttpNetPost("/changepsd", ndata, true);
        if(resData.code === 0){
            Common.Common.PopTips = "Password change successful";
            Common.UserData.actype = 3;
            localStorage?.setItem('LoginAccount', JSON.stringify({account:this.account, password, token:HttpCommon.Token, loginty:this.actype}));
        }
    }

    private sendToken:boolean = false;
    public async LoginByTken(){
        if(Common.UserData || this.sendToken){
            return;
        }
        const loginstr = localStorage?.getItem('LoginAccount');
        if(loginstr){
            let loginac = JSON.parse(loginstr);
            const {account, password, token, loginty} = loginac;
            if(account && token){
                let logindt: LoginByTonken = new LoginByTonken();
                logindt.account = account;
                logindt.token = token;
                this.sendToken = true;
                const resData:NetProtocol = await HttpNetPost("/loginbytoken", logindt, false);
                this.sendToken = false;
                if(resData.code === 0){
                    this.LoginSuccess(resData.data, password, resData.token, loginty);
                }
                else{
                    Common.UserData = null;
                }
            }
        }
    }

    

    public LoginQuit(){
        HttpCommon.Token = "";
        const loginstr = localStorage?.getItem('LoginAccount');
        if(loginstr){
            let loginac = JSON.parse(loginstr);
            const {account, password, token, loginty} = loginac;
            if(token){
                localStorage?.setItem('LoginAccount', JSON.stringify({account, password, loginty}));
            }
        }
    }

    private async UpDataToken(){
        if(HttpCommon.Token !== "" && Common.UserData){
            let ndata = new RefreshToken();
            ndata.account = this.account;
            const resData:NetProtocol = await HttpNetPost("/refreshtoken", ndata, false);
            if(resData.code !== 0){
                Common.UserData = null;
            }
            else{
                this.RefreshtokenTime();
            }
        }
    }

    private timesHander:any;
    private RefreshtokenTime(waittime:number = 1200000){
        if(this.timesHander)
        {
            clearTimeout(this.timesHander);
        }
        this.timesHander = setTimeout(() => {
            this.timesHander = null;
            this.UpDataToken();
        }, waittime);
    }
}