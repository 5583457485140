import { HttpWebGet } from "../../../Net/HttpNetMg";

const verifyurl = 'https://oauth2.googleapis.com/tokeninfo?id_token=';
export default class LoginVerify{
    private static instance:LoginVerify;
    public static get Instance(): LoginVerify{
        if(this.instance == null){
            this.instance = new LoginVerify();
        }
        return this.instance;
    }

    private logindt:any;

    constructor(){

    }

    public get LoginDt():string{
        return this.logindt;
    }

    public async GoogleVerify(tokenStr: string, backfunc:(tonkens:string)=>void){
        const requesurl = verifyurl + tokenStr;
        const {status, text} = await HttpWebGet(requesurl);
        if(status >= 200 && status < 300){
            this.logindt = JSON.parse(text);
            backfunc(this.logindt);
        }
        else{
            backfunc("");
        }
    }
}