export default function CompressImg(imgfile: any){
    return new Promise<any>((resolve, reject) => {
        if (!imgfile) {
            resolve(null);
            return;
        }
        const reader = new FileReader();
        reader.onload = function () {
            const img:any = new Image();
            img.src = reader.result;
            img.onload = function () {
                const canvas:any = document.getElementById('canvas');
                if(canvas){
                    const ctx = canvas.getContext('2d');
                    const maxWidth = 800; // 设置最大宽度为800像素
                    let width = img.width;
                    let height = img.height;
                    // 判断是否需要缩放
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                    // 设置 canvas 的宽高
                    canvas.width = width;
                    canvas.height = height;
                    // 将图片绘制到 canvas 上
                    ctx.drawImage(img, 0, 0, width, height);
                    // 获取压缩后的图片数据
                    const compressedData = canvas.toDataURL('image/jpeg', 0.7); // 可调整质量参数
                    // 创建一个新的压缩后的 File 对象
                    const compressedFile:any = dataURItoBlob(compressedData, imgfile.type);
                    compressedFile.lastModifiedDate = imgfile.lastModifiedDate;
                    compressedFile.name = imgfile.name;
                    // 上传压缩后的图片文件
                    resolve(compressedFile);
                }
                else{
                    resolve(null);
                }
            };
        };
        reader.readAsDataURL(imgfile);
    });
}

function dataURItoBlob(dataURI:any, mimeType:any):Blob {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeType });
}