import { HttpNetPost } from "../Net/HttpNetMg";
import NetProtocol, { PayPalAddTrackingProto, SGetOrthersProto, ServerOrdersProto, product } from "../Net/NetProtocol";
import Common from "./Common";

export default class ServerOrders{
    private static instance:ServerOrders;
    public static get Instance() : ServerOrders{
        if(this.instance == null){
            this.instance = new ServerOrders();
        }
        return this.instance;
    }

    private products:product[] = [];
    private selectporder:string;
    constructor(){
        this.products = [];
        this.selectporder = "";
    }

    public get Products():product[]{
        return this.products;
    }

    public get ShowTrack():boolean{
        return this.selectporder !== "";
    }

    public get SelectPid():string{
        return this.selectporder;
    }

    public set SelectPid(v:string){
        this.selectporder = v;
        Common.Sorders = this;
    } 

    public async GetProduct(netsorder: ServerOrdersProto){
        const resData:NetProtocol = await HttpNetPost("/getsorders", netsorder, false);
        if(resData.code === 0){
            let norders: SGetOrthersProto = resData.data;
            if(norders.orthers){
                this.products = norders.orthers;
                Common.Sorders = this;
            }
        }
        else{
            Common.Common.PopTips = resData.msg;
        }
    }

    public ProAddTracking(tracking: PayPalAddTrackingProto){
        HttpNetPost("/addtracking", tracking, true).then(resData=>{
            if(resData.code === 0){
                let norders = this.products.find(item=>item.orderid === tracking.orderid);
                if(norders){
                    norders.state = 6;
                    norders.trackingnum = tracking.tracking_number;
                    Common.Sorders = this;
                }
            }
        });
    }
}