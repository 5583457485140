import classnames from 'classnames/bind';
import style from './index.module.scss';

const className = classnames.bind(style);
const navList = [
  { label: 'login', value: 1 },
  { label: 'register', value: 2 }
]

interface PropsTarget {
  type: number,
  onChange: (value: number) => void
}
export default function Switch(props: PropsTarget) {
  const {  type, onChange } = props;
  return (
    <section className={className('switch_container')}>
      {navList.map((item: any) => (
        <div
          className={className('item', {loginbtn: item.value === 1}, {registerbtn: item.value === 2}, { active: type === item.value})}
          key={item.value}
          onClick={() => onChange(item.value)}>
          { item.label }
        </div>
      ))}
    </section>
  )
}
