import classnames from 'classnames/bind';
import style from './index.module.scss';
import Common from '../../Common/Common';
import { useRef } from 'react';
import CommonPop from '../CommonPop';
const className = classnames.bind(style);

interface PropsTarget {
   cssStyle?: any
}

const IframePop = (props: PropsTarget)=>{
    const refIframe = useRef<any>()
    const { cssStyle={} } = props;
    const CommonData = Common.Common;
    const closeWindown=()=>{
      CommonData.ShowIframe = false;
    }

    const handleLoad = () => {
      console.log('加载完成');
      Common.Common.ShowLoading = false;
    };

    const handleErro = () => {
      console.log('加载出错');
      Common.Common.ShowLoading = false;
    };

    return(
    <CommonPop closeWindown={closeWindown} titlestr={"Privacy Policy"} cssStyle={cssStyle}>
      <iframe
        className={className('frame')}
        src='https://www.fafafa.store/privacy.html'
        frameBorder="0"
        ref={refIframe}
        onLoad={handleLoad}
        onError={handleErro}
      />
    </CommonPop>
    )
}

export default IframePop;