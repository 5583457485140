import {NetByte} from "./NetByte";

export class NetComparable
{
    constructor() 
    {

    }
    public readData(buffers: NetByte): any{

    }

    public writeData(buffers: NetByte): void{

    }
}

export class IComparString extends NetComparable
{
    constructor(dt: string) 
    {
        super();
        this.data = dt;
    }

    private data : string;
    public get value(): string
    {
        return this.data;
    }

    public set value(dt: string)
    {
        this.data = dt;
    }
}


export class IComparArray extends NetComparable
{
    constructor(dt: any) 
    {
        super();
        this.data = dt;
    }

    private data : any;
    public get value(): any
    {
        return this.data;
    }

    public set value(dt: any)
    {
        this.data = dt;
    }
}


export class IComparNumber extends NetComparable
{
    constructor(dt: number = 0) 
    {
        super();
        this.data = dt;
    }

    private data : number;
    public get value(): number
    {
        return this.data;
    }

    public set value(dt: number)
    {
        this.data = dt;
    }
}