import classnames from 'classnames/bind';
import style from './index.module.scss';
import Common from '../../Common/Common';
import CommonPop from '../CommonPop';
import { UserChatDt } from '../../Common/MgChatData';
import { useEffect, useMemo, useState } from 'react';
import { ChatData } from '../../Net/WsProtocol';
import CompressImg from './CompressImg';
const Imgwhatsapp = '/images/common/whatsapp.png';
const ImgLoading = '/images/common/loadingimg.png';

const className = classnames.bind(style);
interface PropsTarget {
  userchat: UserChatDt|null
}
const ChatPop = (chatdt:PropsTarget)=>{
  const {userchat} = chatdt;
  const [inputdata, updataInput] = useState("");
  const [isLoading, updataLoading] = useState(false);
  const chatdata = useMemo(()=>{ return userchat?.chatdatas}, [userchat?.chatdatas]);
  const userInfo = useMemo(()=>{ return Common.UserData;}, [Common.UserData]);
  const closeFunc = ()=>{
    Common.Chat.SelectChat = null;
  }
  const onSend = ()=>{
    if(inputdata !== ""){
      Common.Chat.SendChat(inputdata);
      updataInput("");
    }
  }
  const onSendImg = async (imgfile:any)=>{
    updataLoading(true);
    if(imgfile.size > 300 * 1024){ // 大于300k 的图片压缩
      imgfile = await CompressImg(imgfile);
    }
    if(!imgfile){
      updataLoading(false);
      return;
    }
    var formData = new FormData();
    formData.append('image', imgfile);
    fetch(`${process.env.PUBLIC_API_BASE}/upload`, {
      method: 'POST',
      body: formData
    }).then(response => response.json())
    .then(data => {
      updataLoading(false);
      console.log(data)
      if(data.code === 0){
        Common.Chat.SendChat("@img="+data.data);
      }
    })
    .catch(error => {
      updataLoading(false);
      console.error('Error:', error)
    });
  }
  const changeInputValue = (values:string)=>{
    updataInput(values);
  }
  const onSelectFlie = (target:any)=>{
    var fileTypes = [".jpg", ".png", ".jpeg", ".PNG", ".gif"];//文件后缀只能是这五类
    var filePath = target.value;
    if(filePath){
        var isNext = false;
        var fileEnd = filePath.substring(filePath.indexOf("."));//截取文件名的后缀
        for (var i = 0; i < fileTypes.length; i++) {
            if (fileTypes[i] == fileEnd) {
                isNext = true;
                break;
            }
        }
        if (!isNext){
            alert('Not accepting this file type');
            target.value = "";
            return false;
        }
        let imgfile =target.files[0];
        onSendImg(imgfile);
    }else {
      return false;
    }
  }
  const chatTitle = (item: ChatData):string=>{
    let chattip = "";
    if(item.official){
      chattip = "customer service  ";
    }
    else{
      chattip = userInfo.actype===100?userchat?.account+" ":"You  ";
    }
    let chattime = new Date(item.times*1000);
    chattip += chattime.toLocaleString();
    return chattip;
  }
  useEffect(()=>{
    Common.Chat.SendRead();
    var element = document.getElementById("chatscrollable");
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  },[]);

  const isImage = (chatstr:string):boolean=>{
    return chatstr.startsWith("@img=");
  }

  const getImgeUrl = (chatstr:string):string=>{
    let imgurl = chatstr.substring(5);
    imgurl = `${process.env.PUBLIC_API_BASE}/image/${imgurl}`;
    return imgurl;
  }

  return(
    <CommonPop closeWindown={closeFunc} titlestr={'Chat Online'}>
    <div className={className('frame')}>
      {
        userInfo?.actype !== 1000 &&
        <div className={className('servicebtn')} onClick={()=>{Common.Common.ShowService = true}}>
          <img src={Imgwhatsapp}/>
        </div>
      }
      <div className={className('chatmessages')} id="chatscrollable">
        {
          chatdata && chatdata.map((item: ChatData, index: number) => (
            <div key={index} className={className('bubble')}>
              <div className={className(item.official?'bubble-title2':'bubble-title1')}>{chatTitle(item)}</div>
              {
                isImage(item.chat)? <img className={className('bubble-image')} src={getImgeUrl(item.chat)}/>
                :<div className={className('bubble-content')}>{item.chat}</div>
              }
            </div>
          ))
        }
      </div>
      <div className={className('chatinput')}>
        <textarea value={inputdata} onChange={e => changeInputValue(e.target.value)} placeholder="Type your message..."/>
        <div className={className('rightbutton')}>
          <div className={className('imgbutton')}>
            <div className={className('txtdiv')}>Select File</div>
            <div className={className('uidiv')}>
                {
                isLoading?
                <img src={ImgLoading}/>
                :<input placeholder="Select Pic" type="file" id="uploadfile" className={className('imgbutton')} onChange={e => onSelectFlie(e.target)} accept="image/*"/>}
            </div>
          </div>
          <button className={className('chatbutton')} onClick={onSend} style={{filter: `grayscale(${inputdata === "" ?1 : 0})`}}>Send</button>
        </div>
      </div>
      <canvas id="canvas" style={{display: 'none'}}></canvas>
    </div>
    </CommonPop>
  )
}

export default ChatPop;