import classnames from 'classnames/bind';
import style from './index.module.scss';
import CommonPop from '../CommonPop';
import Common from '../../Common/Common';
import { PayPalButtons, PayPalScriptProvider, ReactPayPalScriptOptions } from '@paypal/react-paypal-js';
import { PaypalCom } from '../../Api/PaypalCommon';
import { PayPalCancelProto, PayPalCaptureProto, PayPalPayProto, cartdata } from '../../Net/NetProtocol';
import NoData from '../../components/NoData';
import ShowImge from '../ShowImge';
import { useState } from 'react';
import { Define } from '../../Api/Define';
import { pushFbEvent } from '../../Api/FbEven';
const className = classnames.bind(style);

const initialOptions: ReactPayPalScriptOptions = {
    clientId:process.env.PAYPAL_CLIENT_ID||""
};

const ShoppingCard = ()=>{
    const [ImageUrl, SetImageUrl] = useState("");
    const ShowPidImage = (pid:string, imgid:number)=>{
        SetImageUrl(GetImgUrlById(pid, imgid));
    }
    const shopcard = Common.ShopCard;
    const NRouter = Common.Common.NRouter;
    const closeFunc = ()=>{
        shopcard.IsShow = false;
    }
    const GetImgUrlById = (pid:string, imgid:number)=>{
        return "/product/product/" + pid + "/" + imgid + ".jpg";
    }

    const showProduct = (pid:string)=>{
        shopcard.IsShow = false;
        NRouter?.('/Product/'+pid);
    }

    const createOrder = async ()=> {
        const userinfo = Common.UserData;
        if(!userinfo){
            Common.Common.ShowLogin = true;
        }
        else{
            let Price = 0;
            for (let index = 0; index < shopcard.Products.length; index++) {
                const element = shopcard.Products[index];
                Price += Math.round(element.Price * element.Amount * 100)/100;
            }
            let ndata: PayPalPayProto = new PayPalPayProto();
            ndata.account = Common.UserData.account;
            ndata.description = "";
            ndata.price = Price;
            ndata.items = shopcard.Products;
            let orderid = await PaypalCom.MyCreateOrder(ndata);
            return orderid;
        }
    }
    
    const onApprove = async (data:any)=> {
        const userinfo = Common.UserData;
        let ndata: PayPalCaptureProto = new PayPalCaptureProto();
        ndata.account = userinfo.account;
        ndata.orderid = data.orderID;
        const {orderid, status, address} = await PaypalCom.MyCaptureOrder(ndata);
        if(orderid && PaypalCom.GetStatus(status)===PaypalCom.orderstatus.COMPLETED){
            Common.Common.PopTips = "Payment successful";
            let Price = 0;
            let pcontents = [];
            for (let index = 0; index < shopcard.Products.length; index++) {
                const element = shopcard.Products[index];
                Price += Math.round(element.Price * element.Amount * 100)/100;
                pcontents.push({id:element.ProductId,quantity: element.Amount});
            }
            pushFbEvent("Purchase",{
                contents:pcontents,
                content_type:"product",
                currency:"USD",
                value:Price
            })
            if(address){
                if(!Define.CheckUserAddress(address)){
                    Common.Common.ShowAddress = true;
                }
            }
            shopcard.Clear();
        }
    }

    //订单取消
    const onCancel = (data:any)=> {
        const userinfo = Common.UserData;
        if(userinfo){
            let ndata: PayPalCancelProto = new PayPalCancelProto();
            ndata.account = userinfo.account;
            ndata.orderid = data.orderID;
            PaypalCom.CanCelOrder(ndata);
        }
    }

    //订单出错
    const onError = ()=>{
        
    }

    const subname = (namestr: string):string=>{
        if(namestr.length > 10){
            return namestr.substring(0, 10)+"..."
        }
        return namestr;
    }

    return(
        <>
        <CommonPop closeWindown={closeFunc}>
            <div className={className('contentbox')}>
            {
                shopcard.Products.length > 0 &&
                <div className={className('Clear')}>
                    <button onClick={()=>{shopcard.Clear()}}>Clear</button>
                </div>
             }
            <section className={className('datalist')}>
            <div className={className('item')}>
            {
                shopcard.Products.length > 0?
                shopcard.Products.map((item:cartdata,index:number)=>{
                    return(
                        <div className={className('itembox')} key={"p"+index}>
                            <div className={className('picture')}>
                                <img src={GetImgUrlById(item.ProductId, item.ImgeId)} onClick={()=>ShowPidImage(item.ProductId, item.ImgeId)} alt={item.ProductId}/>
                            </div>
                            <div className={className('details')}>
                                <div className={className('itemdiv')} >
                                    <div className={className('title')}>{"No.:"}<span>{subname(item.ProductId)}</span></div>
                                    <div className={className('title')}>{"types:"}<span>{subname(item.Names)}</span></div>
                                </div>
                                <div className={className('itemdiv')} >
                                    <div className={className('title')}>{"amount:"}<span>{item.Amount}</span></div>
                                    <div className={className('title')}>{"price:"}<span>{"$"+(Math.round(item.Amount*item.Price*100)/100).toString()}</span></div>
                                </div>
                            </div>
                            
                            <div className={className('buttonprent')}>
                                <div className={className('clsbutton')}>
                                    <button onClick={()=>{shopcard.RemoveData(item)}}>X</button>
                                </div>
                                <div className={className('gobutton')}>
                                    <button onClick={()=>{showProduct(item.ProductId)}}>details</button>
                                </div>
                            </div>
                        </div>
                    )
                }):
                <NoData />
            }
            </div>
            </section>
            <div className={className('paybutton')}>
                {shopcard.Products.length > 0 && <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onCancel={onCancel}
                        onError={onError}
                        style={{ layout: "horizontal" }}
                    />
                </PayPalScriptProvider>}
            </div>
            </div>
        </CommonPop>
        {
            ImageUrl !== "" &&<ShowImge ImageUrl={ImageUrl} CloseImg={()=>{SetImageUrl("")}}></ShowImge>
        }
        </>
    )
}

export default ShoppingCard;