import classnames from 'classnames/bind'
import style from './index.module.scss'
import Input from '../../../components/Input';

const className = classnames.bind(style);

interface PoropsInter{
  nameInput:any, 
  onUpdateNameInput:any,
  invalidName:any
}

const SignNameInput = (useNameInput:PoropsInter)=>{
    const { nameInput, onUpdateNameInput, invalidName } = useNameInput;
    return(
    <div className={className('nameinput_wrap')}>
      <Input
        required
        mode="email"
        label={"Email Address"}
        value={ nameInput.email }
        changeInputValue={value => onUpdateNameInput('email', value)}
        invalidFlag={invalidName.email.flag}
        invalidMsg={invalidName.email.errMsg}
        placeholder={"Email(Must -have)"}
        inputBlockStyle={{width: '100%'}}
      />
      <Input
        required
        mode="password"
        label={"Password"}
        type="password"
        value={nameInput.password}
        changeInputValue={value => onUpdateNameInput('password', value)}
        invalidFlag={invalidName.password.flag}
        invalidMsg={invalidName.password.errMsg}
      />
    </div>
    )
}

export default SignNameInput;